/* eslint-disable */
import React, { useState, useMemo, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { Grid, TableCell, TableRow } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useContactAttemptsQuery, Role, useCurrentUserQuery } from 'graphql/hooks.generated'
import { UseParamsType } from 'types'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import moment from 'moment'
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  FilterSettingsModel,
  Inject,
  Filter,
  Search,
  Sort,
  Page,
  DetailRow
} from '@syncfusion/ej2-react-grids'
import { useStyles } from '../../../../../config/constants/theme/profile'

interface ListViewParams extends UseParamsType {
  stu_id: string
}

interface Parentparams {
  studentDetails: any
}

const ContactAttemptsInfo = (props: Parentparams) => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const [providername, setProvidername] = useState('')
  const locallang = i18n?.language || 'en'
  const classes = useStyles()
  const { hasRole, user } = useCurrentUser()
  const { studentDetails } = props
  const isSchoolTherapist = hasRole(Role.SchoolTherapist)
  const isTherapist = hasRole(Role.Therapist)
  const isDAdmin = hasRole(Role.SchoolAdmin)
  const { stu_id } = useParams<ListViewParams>()
  const DobTooltip = `${t('errormessages.dobttiptext')}`
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  let stud_id: any
  let decode1
  let decode2
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1
  const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]
  useEffect(() => {
    const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]
    const currentuser_provider =
      (rolesName === Role.Therapist ||
        rolesName === Role.SchoolTherapist ||
        rolesName === Role.AgencySupervisorProvider) &&
      currentUserdata?.data?.currentUser?.id
    if (rolesName === Role.AgencySupervisor || rolesName === Role.AgencySupervisorProvider)
      setProvidername((location.state as string) || (currentuser_provider as string) || '')
    else setProvidername(currentuser_provider || '')
  }, [currentUserdata, location.state])
  const { data, loading, refetch } = useContactAttemptsQuery({
    fetchPolicy: 'network-only',
    variables: { student_id: Number(stud_id), therapist_id: providername },
    skip:
      rolesName === Role.SchoolAdmin || rolesName === Role.TeleteachersAdmin
        ? false
        : !providername
        ? true
        : false
  })
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  const filterOption: FilterSettingsModel = {
    ignoreAccent: true,
    type: 'Excel'
  }
  const RowDataBound = (args) => {
    if (args.data?.client_fill === true) {
      args.row.style.backgroundColor = '#add8e6'
    } else {
      args.row.style.backgroundColor = '#FFFFFF'
    }
  }
  let therapistList

  // if (isDAdmin && data) therapistList = data
  if ((isTherapist || isSchoolTherapist) && data && currentUserdata) {
    therapistList = data?.contact_attempts?.filter(
      (therapist) => therapist.therapistId?.id === currentUserdata.data?.currentUser.id
    )
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.namecard}>
            <div>
              <Avatar style={{ float: 'left' }} className={classes.avatar}>
                {studentDetails &&
                  studentDetails?.name?.split(/[\s]+/).map((value) => value.slice(0, 1))}
              </Avatar>
              <div style={{ float: 'left', padding: '10px' }}>
                {studentDetails && (
                  <>
                    <span style={{ fontWeight: 'bold', color: '#F1583A', fontSize: '22px' }}>
                      {studentDetails?.name}
                    </span>
                    <div>
                      {moment().diff(moment(studentDetails?.birth_date), 'years', false)} years old
                    </div>
                  </>
                )}
              </div>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div
              style={{
                marginTop: '-19px',
                fontFamily: 'Brandon Grotesque'
              }}
            >
              <span
                style={{
                  background: '#ADD8E6',
                  padding: '2px 5px',
                  borderRadius: '5px'
                }}
              >
                Blue
              </span>{' '}
              - Message From Client
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <TableContainer className={classes.updatetable}>
            <Table className={classes.table} aria-label="simple table">
              {data && (
                <GridComponent
                  key={Math.floor(Math.random() * 100)}
                  allowTextWrap
                  width="auto"
                  allowPaging
                  pageSettings={{
                    pageSizes: [15, 25, 50, 100],
                    pageSize: 15,
                    pageCount: 5
                  }}
                  dataSource={
                    isTherapist || isSchoolTherapist ? therapistList : data?.contact_attempts || []
                  }
                  filterSettings={filterOption}
                  rowDataBound={RowDataBound}
                  enableHover={false}
                  allowSelection={false}
                  // allowFiltering
                  allowSorting
                >
                  <ColumnsDirective>
                    <ColumnDirective width="20" />
                    <ColumnDirective
                      field="sent_date"
                      template={(dates) => (
                        <>
                          <div>
                            {moment.tz(dates?.sent_date, 'America/Denver').format('MM/DD/YYYY')}
                            &nbsp; &nbsp;
                            {moment.tz(dates?.sent_date, 'America/Denver').format('hh:mm a')}
                          </div>
                        </>
                      )}
                      headerText="Date"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="to"
                      headerText="To"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="from"
                      headerText="From"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="subject"
                      headerText="Subject/Notes"
                      width="100"
                      allowFiltering={false}
                    />
                  </ColumnsDirective>

                  <Inject services={[Filter, Search, Sort, Page, DetailRow]} />
                </GridComponent>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {isLoading()}
    </div>
  )
}

export default ContactAttemptsInfo
