import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import { Dialog, DialogActions, DialogContent, DialogContentText, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import Button from 'components/ui/panel/button/Button'
import { useErrorHandler } from 'graphql/hooks/useErrorHandler/useErrorHandler'
import NavBar from '../../../../components/ui/panel/nav-bar/NavBar'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import { UserForm } from '../form/UserForm'
import { UserFormModel } from '../form/UserFormModel'
import {
  User,
  District,
  useUpdateUserMutation,
  useUserQuery,
  State,
  Student,
  TherapistDescipline,
  useDisableUserMutation,
  useCurrentUserQuery,
  useEnableUserMutation
} from '../../../../graphql/hooks.generated'
import { UseParamsType } from '../../../../types'

interface UpdateUserViewParams extends UseParamsType {
  id: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const UpdateUserView = () => {
  const history = useHistory()
  const { id } = useParams<UpdateUserViewParams>()
  const [updateUser, { loading }] = useUpdateUserMutation()
  const { data, loading: dataLoading, refetch } = useUserQuery({
    variables: { id },
    fetchPolicy: 'network-only'
  })
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [disableUser, { loading: disableLoader }] = useDisableUserMutation()
  const [enableUser, { loading: enableLoading }] = useEnableUserMutation()
  const handleError = useErrorHandler()
  const [open, setOpen] = React.useState(false)
  const handleDisableUser = (id) => {
    disableUser({
      variables: { id, updated_by: currentUserdata?.data?.currentUser?.id }
    })
      // .then(() => refetch())
      .then(history.goBack)
  }
  const handleEnableUser = (id) => {
    enableUser({ variables: { id, updated_by: currentUserdata?.data?.currentUser?.id } })
      // .then(() => refetch())
      .then(history.goBack)
  }
  const handleClose = () => {
    setOpen(false)
    // console.log(records)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const user:
    | ({
        __typename?: 'User'
        district?: ({ __typename?: 'District' } & Pick<District, 'id' | 'name'>) | null
        // stateLicense?:({ __typename?: 'State'} & Pick<State, 'id' | 'name'>) | null
        student_id?: ({ __typename?: 'Student' } & Pick<Student, 'id' | 'name'>) | null
        // signalDiscipline?:
        //   | ({ __typename?: 'TherapistDescipline' } & Pick<TherapistDescipline, 'id' | 'name'>)
        //   | null
      } & Pick<
        User,
        | 'email'
        | 'first_name'
        | 'last_name'
        | 'middle_initial'
        | 'maiden_name'
        | 'roles'
        // | 'telemedicine_enabled'
        // | 'telemedicine_trained_at'
        | 'state_license'
        | 'mobile_phone'
        | 'home_phone'
        | 'street_address'
        | 'license_number'
        | 'cert_date'
        | 'cert_expire_date'
        | 'npi'
        | 'zoom_id'
        | 'hourly_rate'
        | 'time_zone'
        // | 'signalAgeGroup'
        | 'newUserAgeGroups'
        | 'signalCounties'
        | 'signalInsurance'
        | 'signal_service_id'
        | 'signalSpecilities'
        | 'signalLanguage'
        | 'signalAgencies'
        | 'signalDiscipline'
        | 'ethinic_group_id'
        | 'signalUserExperience'
        | 'status'
        | 'gender'
        | 'password'
        | 'degree_held'
        | 'school_attended'
        | 'clinician_phone'
        | 'specialized_training'
        | 'supervisor_name_and_credentials'
        | 'waitlist_assignee'
        | 'is_mfa'
        | 'updated_by'
        | 'preferred_name'
      >)
    | undefined = data?.user
  const initialValues: UserFormModel | null = user
    ? {
        district: user.district?.id ?? '',
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name ?? '',
        middleInitial: user.middle_initial ?? undefined,
        roles: user.roles ?? [],
        studentId: user.student_id?.id,
        // telemedicineEnabled: user.telemedicine_enabled ?? undefined,
        // telemedicineTrainedAt: user.telemedicine_trained_at
        //   ? new Date(user.telemedicine_trained_at)
        //   : undefined,
        mobilePhone: user.mobile_phone ?? null,
        homePhone: user.home_phone ?? null,
        stateLicense: user.state_license?.id,
        streetAddress: user.street_address ?? null,
        licenseNumber: user.license_number ?? null,
        zoomId: user.zoom_id ?? null,
        npi: user.npi ?? null,
        hourlyRate: user.hourly_rate ?? null,
        timeZone: user.time_zone ?? null,
        // therapistDiscipline: user.therapist_discipline ? user.therapist_discipline : undefined,
        certDate: user.cert_date ? new Date(user.cert_date) : undefined,
        certExpireDate: user.cert_expire_date ? new Date(user.cert_expire_date) : undefined,
        // signalDiscipline: user.signalDiscipline ?? null,
        // min_age:

        //   user.signalAgeGroup?.map((itm) => {
        //     return itm.id
        //   }) ?? null,
        // max_age:
        //   user.signalAgeGroup?.map((itm) => {
        //     return itm.id
        //   }) ?? null,
        min_age: user.newUserAgeGroups?.min_age ?? undefined,

        max_age: user.newUserAgeGroups?.max_age ?? undefined,
        signalEthnicGroup: user.ethinic_group_id?.id ?? null,
        signalCounties:
          user?.signalCounties?.map((itm) => {
            return itm.id
          }) ?? null,
        signalInsurance:
          user?.signalInsurance?.map((itm) => {
            return itm.id
          }) ?? null,
        signalServiceId: user?.signal_service_id?.id ?? null,
        signalSpecilities:
          user?.signalSpecilities?.map((itm) => {
            return itm.id
          }) ?? null,
        signalLanguage:
          user.signalLanguage?.map((itm) => {
            return itm.id
          }) ?? null,
        signalAgencies:
          user.signalAgencies?.map((itm) => {
            return itm.id
          }) ?? null,
        signalDiscipline: user?.signalDiscipline?.id ?? null,
        signalUserExperience: user?.signalUserExperience?.id ?? null,
        status: user.status,
        gender: user.gender ?? null,
        password: user.password ?? null,
        degree_held: user.degree_held ?? null,
        school_attended: user.school_attended ?? null,
        clinician_phone: user.clinician_phone ?? null,
        specialized_training: user.specialized_training ?? null,
        supervisor_name_and_credentials: user.supervisor_name_and_credentials ?? null,
        waitlist_assignee: user.waitlist_assignee ?? null,
        is_mfa: user.is_mfa ?? null,
        updated_by: currentUserdata?.data?.currentUser?.id ?? null,
        preferred_name: user.preferred_name ?? null
      }
    : null
  const useractions = () => {
    if (initialValues?.status === 'ACTIVE') {
      handleDisableUser(id)
      setOpen(false)
    } else {
      handleEnableUser(id)
      setOpen(false)
    }
  }
  let checkPassword

  if (initialValues?.password?.length) checkPassword = true
  else checkPassword = false

  return (
    <>
      {(loading || disableLoader || enableLoading || dataLoading) && (
        <ProgressIndicator fullHeight />
      )}
      <NavBar title="Update User" goBackBtn />
      <PageContainer>
        <UserForm
          submitLabel="Save"
          isUpdate
          initialValues={initialValues}
          userdata={data?.user}
          onSubmit={({
            district,
            email,
            firstName,
            lastName,
            middleInitial,
            roles,
            studentId,
            zoomId,
            npi,
            hourlyRate,
            timeZone,
            // telemedicineEnabled,
            // telemedicineTrainedAt,
            stateLicense,
            // therapistDiscipline,
            mobilePhone,
            homePhone,
            streetAddress,
            licenseNumber,
            certDate,
            certExpireDate,
            signalDiscipline,
            min_age,
            max_age,
            signalEthnicGroup,
            signalCounties,
            signalInsurance,
            signalServiceId,
            signalSpecilities,
            signalLanguage,
            signalAgencies,
            signalUserExperience,
            sendInvite,
            gender,
            degree_held,
            school_attended,
            clinician_phone,
            specialized_training,
            supervisor_name_and_credentials,
            waitlist_assignee,
            is_mfa,
            updated_by,
            mailVlaue,
            preferred_name
          }: UserFormModel) =>
            updateUser({
              variables: {
                id,
                input: {
                  district,
                  email,
                  firstName,
                  lastName,
                  middleInitial,
                  roles,
                  studentId,
                  zoomId,
                  npi,
                  hourlyRate,
                  timeZone,
                  // telemedicineEnabled,
                  // telemedicineTrainedAt: telemedicineTrainedAt?.toISOString(),
                  stateLicense,
                  // therapistDiscipline,
                  mobilePhone,
                  homePhone,
                  streetAddress,
                  licenseNumber,
                  certDate: certDate && format(certDate, 'yyyy-MM-dd'),
                  certExpireDate: certExpireDate && format(certExpireDate, 'yyyy-MM-dd'),
                  signalDiscipline,
                  // signalAgeGroup,
                  min_age,
                  max_age,
                  signalEthnicGroup,
                  signalCounties,
                  signalInsurance,
                  signalServiceId,
                  signalSpecilities,
                  signalLanguage,
                  signalAgencies,
                  signalUserExperience,
                  sendInvite,
                  gender,
                  degree_held,
                  school_attended,
                  clinician_phone,
                  specialized_training,
                  supervisor_name_and_credentials,
                  waitlist_assignee,
                  is_mfa,
                  updated_by,
                  mailVlaue,
                  preferred_name
                },
                updateMail: initialValues?.email,
                passwordCheck: checkPassword
              }
            })
              .then(history.goBack)
              .catch(handleError)
          }
        />
        {initialValues?.password?.length ? (
          <Button
            variant="outlined"
            color="primary"
            size="large"
            style={{ marginLeft: 1050, top: '-55px' }}
            onClick={
              () => handleClickOpen()
              // initialValues?.status === 'ACTIVE' ? handleDisableUser(id) : handleEnableUser(id)
            }
          >
            {initialValues?.status === 'ACTIVE' ? 'Disable' : 'Enable'}
          </Button>
        ) : null}
      </PageContainer>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`Are you sure want to ${
                initialValues?.status === 'ACTIVE' ? 'disable user' : 'enable user'
              }`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={useractions} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
