import { Box, Paper, styled, Typography, Grid, Avatar } from '@material-ui/core'
import { TextField, Checkboxes } from 'mui-rff'
import moment from 'moment'
import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory, useParams } from 'react-router-dom'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs'
import { UseParamsType } from '../../../../types'
import Button from '../../../../components/ui/panel/button/Button'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import {
  useCurrentUserQuery,
  useSignalSlotsQuery,
  useGetNewProfileSlotsLazyQuery,
  useUpdateRecurringSlotsMutation,
  useGetUserDetailsInProfileLazyQuery
} from '../../../../graphql/hooks.generated'
import BlankImage from '../../../../assets/icons/blank.svg'
import { ScheduleDayEditModal } from './scheduledayeditmodal'
import { DetailView } from './DetailView'

interface ListUserViewParams extends UseParamsType {
  userid: string
}
interface ActivateAccountFormModel {
  name: string
  email: string
  aboutMe: any
  userImg: any
  recurrence: boolean
}
const FormRow = styled(Box)({
  padding: '12px 32px'
})
const ButtonsRow = styled(Box)({
  padding: '32px 0px'
})
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    repeatavail: {
      '& .MuiTypography-body1': {
        fontSize: '15px',
        fontFamily: 'Brandon Grotesque'
      }
    },
    margin: {
      margin: theme.spacing(1)
    },
    rightIcon: {
      '& :visited': { color: 'blue' },
      '& :hover': { color: 'blue' },
      '& :active': { color: 'blue' }
    },
    leftIcon: {
      color: 'slategray'
    },
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      padding: theme.spacing(1)
    },
    texBox: {
      '& .MuiFilledInput-root': {
        border: '1px solid',
        background: '#fff !important'
      }
    },
    upload: {
      '& .e-upload': {
        border: 'none'
      }
    },
    imgContainer: { display: 'flex', height: '152px', padding: '25px' },
    previewImg: {
      height: '100%',
      width: '25%',
      padding: '20px',
      borderRight: ' 1px solid #c1c1c1'
    },
    passWordText: {
      fontSize: '16px',
      paddingLeft: '35px',
      fontWeight: 500,
      margin: 0
    },
    saveBtn: {
      marginLeft: '15px'
    },
    outercont: {
      width: '85%',
      margin: 'auto'
    },
    customAvatar: {
      width: '80px',
      height: '80px',
      marginRight: '18px',
      marginTop: '4px'
    },
    inpersonBg: {
      background: '#90EE90'
    },
    teleBg: {
      background: '#ADD8E6'
    },
    bothBg: {
      background: '#FFFF66'
    }
  })
)

export const TherapistInviteUpdateView = () => {
  const { userid: ids } = useParams<ListUserViewParams>()
  const createSlotObj = {
    user_id: '',
    user_availability_id: 0,
    week_day: 0,
    slot_date: ''
  }
  const uploadObj = useRef<UploaderComponent>(null)
  const history = useHistory()
  const [picture, setPicture] = useState<any>('')
  const [uploadStatus, setUploadStatus] = React.useState(null)
  const [uploadError, setUploadError] = React.useState(false)
  const [uploadvalidation, setUploadValidation] = React.useState(false)
  const [slotsDropData, setSlotsDropData] = useState<any[]>([])
  const [open, setOpen] = useState(false)
  const [slotDet, setSlotDet] = useState<any[]>([])
  const classes = useStyles()
  let newProfSlots: any[]
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const { data: slotsDropdownData, loading: slotsDropdownLoading } = useSignalSlotsQuery({
    fetchPolicy: 'network-only'
  })
  const [
    getTherapistDetl,
    { data: userObj, loading: userObjLoading }
  ] = useGetUserDetailsInProfileLazyQuery({ fetchPolicy: 'network-only' })
  const [
    fetchListSlots,
    { data: listslots, loading: listSlotsLoading }
  ] = useGetNewProfileSlotsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [profileUpdateMutation, { loading: availrecLoading }] = useUpdateRecurringSlotsMutation()
  useEffect(() => {
    if (ids)
      getTherapistDetl({
        variables: {
          user_id: ids
        }
      })
    if (currentUserdata && !ids)
      getTherapistDetl({
        variables: {
          user_id: currentUserdata?.data?.currentUser?.id
        }
      })
  }, [currentUserdata, getTherapistDetl, ids])
  useEffect(() => {
    if (userObj) {
      const userfinalObj2 = userObj?.getUserDetailsInProfile
      setPicture(userfinalObj2?.picture)
    }
  }, [userObj, setPicture])
  useEffect(() => {
    if (slotsDropdownData?.signalSlots) setSlotsDropData(slotsDropdownData?.signalSlots)
  }, [slotsDropdownData])
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }
  const initialObj = useMemo(() => {
    const obj = {
      name: '',
      email: '',
      description: '',
      picture: '',
      recurrence: false
    }

    if (currentUserdata) {
      obj.name = currentUserdata.data?.currentUser?.email || ''
      obj.email = currentUserdata.data?.currentUser.email || ''
    }
    if (userObj) {
      const userfinalObj = userObj?.getUserDetailsInProfile
      obj.description = userfinalObj?.description || ''
      obj.name = userfinalObj?.name || ''
      obj.email = userfinalObj?.email || ''
      obj.recurrence = userfinalObj?.recurrence === 1 || false
    }

    return obj
  }, [userObj, currentUserdata])
  useEffect(() => {
    if (ids)
      fetchListSlots({
        variables: {
          user_id: ids,
          timeperiod: new Date().toISOString()
        }
      })
    if (currentUserdata && !ids)
      fetchListSlots({
        variables: {
          user_id: currentUserdata?.data?.currentUser?.id,
          timeperiod: new Date().toISOString()
        }
      })
  }, [currentUserdata, fetchListSlots, ids])
  const imageUpload = async (data) => {
    setUploadStatus(data?.filesData[0]?.status)
    setUploadError(true)
    setUploadValidation(
      !!(
        data?.filesData[0]?.validationMessages?.minSize ||
        data?.filesData[0]?.validationMessages?.maxSize
      )
    )
    const base64 = await convertBase64(data?.filesData[0]?.rawFile)
    setPicture(base64)
  }
  const handleFormSubmit = async (values) => {
    values.picture = picture

    if (uploadvalidation) return

    let newInputData
    const inputData = {
      user_id: ids || currentUserdata?.data?.currentUser.id || '',
      description: values.description,
      recurrence: values.recurrence
    }

    if (values.picture && !values.picture.includes('https://'))
      newInputData = { ...inputData, picture: values.picture }
    else newInputData = inputData

    try {
      await profileUpdateMutation({
        variables: newInputData
      })

      if (ids) history.push(`/therapists`)
      else history.push(`/`)
    } catch (e) {
      console.log(e)
    }
  }

  if (listslots) {
    newProfSlots = []
    Object.entries(listslots?.getNewProfileSlots).forEach(([k, v], index) => {
      newProfSlots[index] = v
    })
  }

  const handleClose = () => {
    setOpen(false)
    fetchListSlots({
      variables: {
        user_id: ids || currentUserdata?.data?.currentUser?.id,
        timeperiod: new Date().toISOString()
      }
    })
  }
  const dayEdit = (e, slotdel) => {
    setSlotDet(slotdel)
    setOpen(true)
  }
  const renderForm = (renderProps: FormRenderProps<ActivateAccountFormModel>) => {
    const { handleSubmit, values } = renderProps

    return (
      <>
        <Grid container className={classes.outercont} style={{ paddingTop: '20px' }}>
          <Grid item xs={6} sm={6}>
            <FormRow>
              <TextField name="name" variant="filled" disabled fullWidth label="Full name" />
            </FormRow>
            <FormRow>
              <TextField name="email" variant="filled" disabled fullWidth label="Email" />
            </FormRow>
          </Grid>
          <Grid item xs={6} sm={6} style={{ paddingRight: '35px' }}>
            <Paper>
              <div className={classes.imgContainer}>
                <Avatar alt="user" className={classes.customAvatar} src={picture} />
                {/* <img src={picture || BlankImage} alt="USER" className={classes.previewImg} /> */}
                <div className={classes.upload} style={{ paddingLeft: '20px' }}>
                  <UploaderComponent
                    ref={uploadObj}
                    id="fileUpload"
                    name="imageUpload"
                    type="file"
                    style={{ border: 'none' }}
                    maxFileSize={500000}
                    allowedExtensions=".png, .jpg, .jpeg, .svg"
                    multiple={false}
                    change={(file) => imageUpload(file)}
                    showFileList={false}
                    autoUpload={false}
                    selected={(file) => imageUpload(file)}
                  />
                  {uploadError && (
                    <p
                      style={{
                        color: uploadStatus === 'Ready to upload' ? '#2ebb4c' : '#ff0000',
                        paddingLeft: '12px'
                      }}
                    >
                      {uploadStatus}
                    </p>
                  )}
                </div>
              </div>
            </Paper>
          </Grid>
          {/* new details */}
          {!ids && currentUserdata && (
            <DetailView userid={currentUserdata?.data?.currentUser?.id} />
          )}
          {ids && <DetailView userid={ids} />}
          {/* new details end */}
          <Grid xs={12} sm={12} style={{ paddingTop: '2rem' }}>
            <FormRow>
              <TextField
                name="description"
                variant="filled"
                fullWidth
                label="About Me"
                className={classes.texBox}
                multiline
                rows={3}
              />
            </FormRow>
          </Grid>
          <Grid item xs={12} style={{ padding: '1px 30px' }}>
            <Typography>
              <span className="timeZoneTxt">
                <span>All times shown in Mountain(MT) </span> Hawaiian time = -3 hrs, Alaskan Time =
                -2 hrs, Pacific Time = -1 hr, Central Time = +1 hr, Eastern Time = +2 hrs
              </span>
            </Typography>
            <h3>Availability</h3>
            <div style={{ fontSize: '15px', fontWeight: 600 }}>
              <span style={{ background: '#ADD8E6', padding: '2px 5px', borderRadius: '5px' }}>
                Blue
              </span>{' '}
              - Telehealth Only, &nbsp;&nbsp;&nbsp;
              <span style={{ background: '#90EE90', padding: '2px 5px', borderRadius: '5px' }}>
                Green
              </span>{' '}
              - In Person Only, &nbsp;&nbsp;&nbsp;
              <span style={{ background: '#FFFF66', padding: '2px 5px', borderRadius: '5px' }}>
                Yellow
              </span>{' '}
              - Both
            </div>
            <div className={classes.repeatavail}>
              <Checkboxes
                name="recurrence"
                formControlProps={{ margin: 'none' }}
                data={{ label: 'Repeat Schedule', value: false }}
              />
            </div>
          </Grid>
          <Grid container item xs={12} sm={12} spacing={1}>
            <Grid md={6}>
              {newProfSlots?.length > 0 &&
                newProfSlots.map((day, index) => (
                  <>
                    {(newProfSlots?.length - 1) / 2 > index && index < 14 && (
                      <Grid item xs={12} style={{ padding: '5px 25px 5px 30px' }}>
                        <div style={{ display: 'flex' }}>
                          <Grid md={4} color="secondary">
                            <Typography style={{ fontFamily: 'Brandon Grotesque' }}>
                              {moment(day[0]?.timeperiod, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                              &nbsp;-&nbsp;{day[0]?.dayname}
                            </Typography>
                          </Grid>
                          <Grid md={7} style={{ padding: '5px' }}>
                            {day &&
                              day.map((fixedSlots) => (
                                <>
                                  {fixedSlots?.stime && fixedSlots?.etime && (
                                    <span
                                      style={{
                                        padding: '5px',
                                        marginRight: '5px',
                                        marginBottom: '10px',
                                        fontWeight: 600,
                                        borderRadius: '5px',
                                        display: 'inline-block',
                                        float: 'left',
                                        fontSize: '13px'
                                      }}
                                      className={
                                        fixedSlots?.service_type_id === 1
                                          ? classes.inpersonBg
                                          : fixedSlots?.service_type_id === 2
                                          ? classes.teleBg
                                          : fixedSlots?.service_type_id === 3
                                          ? classes.bothBg
                                          : ''
                                      }
                                    >
                                      {moment(fixedSlots?.stime, 'hh:mm:ss').format('hh:mm A')}
                                      &nbsp;-&nbsp;
                                      {moment(fixedSlots?.etime, 'hh:mm:ss').format('hh:mm A')}
                                    </span>
                                  )}
                                </>
                              ))}
                          </Grid>
                          <Grid md={1}>
                            <Button
                              variant="outlined"
                              size="medium"
                              onClick={(e) => dayEdit(e, day)}
                            >
                              Edit
                            </Button>
                          </Grid>
                        </div>
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
            <Grid md={6}>
              {newProfSlots?.length > 0 &&
                newProfSlots.map((day, index) => (
                  <>
                    {(newProfSlots?.length - 1) / 2 <= index && index < 14 && (
                      <Grid item xs={12} style={{ padding: '5px 25px' }}>
                        <div style={{ display: 'flex' }}>
                          <Grid md={4} color="secondary">
                            <Typography style={{ fontFamily: 'Brandon Grotesque' }}>
                              {moment(day[0]?.timeperiod, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                              &nbsp;-&nbsp;
                              {day[0]?.dayname}
                            </Typography>
                          </Grid>
                          <Grid md={7} style={{ padding: '5px' }}>
                            {day &&
                              day.map((fixedSlots) => (
                                <>
                                  {fixedSlots?.stime && fixedSlots?.etime && (
                                    <div
                                      style={{
                                        padding: '5px',
                                        marginRight: '5px',
                                        marginBottom: '10px',
                                        fontWeight: 600,
                                        borderRadius: '5px',
                                        display: 'inline-block',
                                        float: 'left',
                                        fontSize: '13px'
                                      }}
                                      className={
                                        fixedSlots?.service_type_id === 1
                                          ? classes.inpersonBg
                                          : fixedSlots?.service_type_id === 2
                                          ? classes.teleBg
                                          : fixedSlots?.service_type_id === 3
                                          ? classes.bothBg
                                          : ''
                                      }
                                    >
                                      {moment(fixedSlots?.stime, 'hh:mm:ss').format('hh:mm A')}
                                      &nbsp;-&nbsp;
                                      {moment(fixedSlots?.etime, 'hh:mm:ss').format('hh:mm A')}
                                    </div>
                                  )}
                                </>
                              ))}
                          </Grid>
                          <Grid md={1}>
                            <Button
                              variant="outlined"
                              size="medium"
                              onClick={(e) => dayEdit(e, day)}
                            >
                              Edit
                            </Button>
                          </Grid>
                        </div>
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <ButtonsRow>
              <Box display="flex" justifyContent="center">
                <Button
                  className={classes.saveBtn}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Box>
            </ButtonsRow>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      {(slotsDropdownLoading || listSlotsLoading || availrecLoading || userObjLoading) && (
        <ProgressIndicator fullHeight />
      )}
      <Form<ActivateAccountFormModel>
        render={renderForm}
        initialValues={initialObj}
        onSubmit={handleFormSubmit}
      />
      {open && (
        <ScheduleDayEditModal
          open={open}
          handleClose={handleClose}
          slotDetails={slotDet}
          serviceid={userObj?.getUserDetailsInProfile?.signal_service_id}
        />
      )}
    </>
  )
}
