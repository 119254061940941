// @todo: return activate query logic after DB separtion works done
/* eslint-disable */
import { Box, Paper, styled, Typography, Grid, Icon, Popover, Avatar } from '@material-ui/core'
import Button from 'components/ui/panel/button/Button'
import { PageContainer } from 'components/ui/panel/page-container/PageContainer'
import {
  useActivateAccountMutation,
  useUserInviteQuery,
  useSignalSlotsLazyQuery,
  useNextDaysLazyQuery,
  useUserPicBioLazyQuery
} from 'graphql/hooks.generated'
// import { useUserInviteQuery } from 'graphql/hooks.generated'
import { makeValidate, TextField } from 'mui-rff'
import React, { useEffect, useState, useMemo, useRef } from 'react'
import moment from 'moment'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InfoIcon from '@material-ui/icons/Info'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs'
import IconButton from 'components/ui/panel/icon-button/IconButton'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
// import { theme } from 'config/constants/theme'
// import BlankImage from '../../../assets/icons/blank.svg'

interface ActivateAccountParams {
  token: string
}

interface ActivateAccountFormModel {
  password: string
  passwordConfirmation: string
  aboutMe: any
}
interface SlotDropdownModel {
  name: string
  values: any
}
const StyledPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,
  margin: 'auto',
  width: 400
}))
const PaperHeader = styled(Box)({
  padding: 32
})
const FormRow = styled(Box)({
  padding: '12px 32px'
})
const ButtonsRow = styled(Box)({
  padding: '32px'
})
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1)
    },
    rightIcon: {
      '& :visited': { color: 'blue' },
      '& :hover': { color: 'blue' },
      '& :active': { color: 'blue' }
    },
    leftIcon: {
      color: 'slategray'
    },
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      padding: theme.spacing(1)
    },
    texBox: {
      '& .MuiFilledInput-root': {
        border: '1px solid',
        background: '#fff !important',
        marginTop: '7px'
      }
    },
    upload: {
      '& .e-upload': {
        border: 'none'
      }
    },
    imgContainer: { display: 'flex', height: '152px', padding: '25px' },
    previewImg: {
      height: '100%',
      width: '25%',
      padding: '20px',
      borderRight: ' 1px solid #c1c1c1'
    },
    customAvatar: {
      width: '80px',
      height: '80px',
      marginRight: '18px',
      marginTop: '4px'
    },
    passWordText: {
      fontSize: '16px',
      paddingLeft: '35px',
      fontWeight: 500,
      margin: 0
    },
    availableText: {
      fontSize: '19px',
      paddingLeft: '35px',
      fontWeight: 500,
      margin: 0
    }
  })
)
const validator = makeValidate(
  yup.object<ActivateAccountFormModel>({
    // password: yup.string().label('Password').min(8).required(),
    aboutMe: yup
      .string()
      .max(500, 'About me is exceeded more that 500 charactors')
      .required('About me is required')
      .when('aboutValue', {
        is: (val) => val != (null || undefined),
        then: yup.string().notRequired(),
        otherwise: yup.string().required()
      }),
    password: yup
      .string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{7,15}$/,
        `Password should contain 8 - 15 characters with at least one uppercase, lowercase, numeric
        and special character, white space not allowed!`
      ),
    passwordConfirmation: yup
      .string()
      .label('Password confirmation')
      .required()
      .equals([yup.ref('password')], '${path} must be the same as password')
  })
)

export const TherapistInviteView = () => {
  const uploadObj = useRef<UploaderComponent>(null)
  const history = useHistory()
  const params = useParams<ActivateAccountParams>()
  const [shownewPaswd, toggleNewPaswd] = useState<boolean>(false)
  const [userImg, setUserImg] = useState<any>(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [uploadStatus, setUploadStatus] = React.useState(null)
  const [uploadError, setUploadError] = React.useState(false)
  const classes = useStyles()
  const [aboutDisable, setAboutDisable] = useState<boolean>(false)
  const [aboutValue, setAboutValue] = useState<any>(undefined)
  const days: any[] = [
    { id: 0, name: 'Mon' },
    { id: 4, name: 'Fri' },
    { id: 1, name: 'Tue' },
    { id: 5, name: 'Sat' },
    { id: 2, name: 'Wed' },
    { id: 6, name: 'Sun' },
    { id: 3, name: 'Thu' }
  ]
  const fields = { text: 'time_slot', value: 'id' }
  const welcomeMsg = 'Welcome! You are now part of the I Matter Program!'
  const welcomeSubTxt =
    'As part of this program, you will help Colorado youth develop coping skills, become familiar with behavioral health services and receive the support they need to heal and thrive. We are so glad that you are a part of this groundbreaking initiative.'
  // const [showErrorPattern, setErrorPattern] = useState<string>('')
  const InfoPopOver = () => {
    return (
      <>
        <p>
          <strong>So, what&rsquo;s next?</strong> <br />
          We have already created a profile for you, so we ask that you do the following quick steps
          to complete the set-up process:
        </p>
        <ul>
          <li>
            <strong>Verify.</strong> Check to make sure everything in your profile is accurate.
          </li>
          <li>
            <strong>Customize</strong>. Add additional information the specific services you provide
            or areas that you specialize in.
          </li>
          <li>
            <strong>Personalize</strong>. Tell clients a bit about yourself. Add 2-3 sentences
            telling them what you&rsquo;re all about. Also feel free to add a photo of yourself if
            you&rsquo;d like.
          </li>
          <li>
            <strong>Finalize</strong>. Hit the &ldquo;???&rdquo; button and you are all set!
          </li>
        </ul>
        <p>Now that your profile is finalized, here are a couple of things you need to know. </p>
        <ul>
          <li>
            You control your calendar. As a provider of the program, you will be in control of your
            own schedule. Please be sure to keep it updated in the system so that clients know when
            you&rsquo;re available.
          </li>
          <li>
            You will indicate in your calendar if the appointment time is available to be provided
            in-person and/or through telehealth.
          </li>
          <li>
            When a client schedules an appointment with you, both you and the client will receive a
            confirmation email with the appointment details, including whether the service will be
            provided in-person or through our telehealth platform.{' '}
          </li>
          <li>
            If the appointment is offered in-person, the office address will be provided in the
            calendar and in the email confirmation.
          </li>
          <li>
            If the appointment is offered through telehealth, the email confirmation to you and the
            client will contain the telehealth login information.{' '}
          </li>
          <li>
            If you need technical assistance with the calendar or telehealth platform, please
            contact us at XXXXXXXX
          </li>
        </ul>
      </>
    )
  }
  const initialValues: ActivateAccountFormModel = {
    aboutMe: aboutValue,
    password: '',
    passwordConfirmation: ''
  }
  const { data, loading, error } = useUserInviteQuery({
    variables: { token: params.token }
  })
  //   const { data: slotsDropdownData } = useSignalSlotsQuery({
  //     fetchPolicy: 'network-only'
  //   })
  const [profilebio, { data: profileBio }] = useUserPicBioLazyQuery({
    variables: { id: String(data?.userInvite?.id) }
  })
  const [executeQuery, { data: slotsDropdownData }] = useSignalSlotsLazyQuery({
    fetchPolicy: 'network-only'
  })
  const [slotDateQuery, { data: slotsDates }] = useNextDaysLazyQuery({
    fetchPolicy: 'network-only'
  })
  // console.log(slotsDates)
  const fetch = useMemo(() => () => executeQuery(), [executeQuery])
  const slotDatesFetch = useMemo(() => () => slotDateQuery(), [slotDateQuery])
  const profilePicBio = useMemo(() => () => profilebio(), [profilebio])
  useEffect(() => {
    fetch()
    slotDatesFetch()
    profilePicBio()
  }, [fetch, slotDatesFetch, profilePicBio])
  const [slotsDropData, setSlotsDropData] = useState<any[]>([])
  const [slotsSubmitData, setSlotsSubmitData] = useState<any>({})
  useEffect(() => {
    if (slotsDropdownData?.signalSlots) setSlotsDropData(slotsDropdownData?.signalSlots)
  }, [slotsDropdownData])
  useEffect(() => {
    if (profileBio?.userPicBio?.description) {
      setAboutDisable(true)
      setAboutValue(profileBio?.userPicBio?.description)
    }
  }, [setAboutDisable, profileBio, setAboutValue])
  const [activateAccount, { loading: signuploading }] = useActivateAccountMutation()

  if (loading) return <div>Loading invite info...</div>
  if (error)
    // console.log(error)
    return <div>{error.message}</div>
  // setErrorPattern(error.graphQLErrors[0].message)

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }
  const imageUpload = async (data) => {
    // setUserImg(URL.createObjectURL(data.target.files[0]))
    setUploadStatus(data?.filesData[0]?.status)
    setUploadError(true)
    const base64 = await convertBase64(data?.filesData[0]?.rawFile)
    setUserImg(base64)
  }
  const submitSlotArray = () => {
    const submitSlotArray: any = []

    if (Object.keys(slotsSubmitData)?.length > 0) {
      const finalSlotArray = Object.keys?.(slotsSubmitData)?.map((itm) => {
        const timeSlots: any = slotsSubmitData[itm]?.timeSlot
        const slotArray = timeSlots?.map((slot) => {
          const slotObj = {
            user_availability_id: slot,
            week_day: slotsSubmitData[itm].week.weekday,
            slot_date: moment(itm, 'MM-DD-YYYY').format('MM/DD/YYYY')
          }
          submitSlotArray.push(slotObj)

          return slotObj
        })

        return slotArray
      })
    }

    return submitSlotArray

    // setSlotsSubmitData({ ...slotsSubmitData, [week.name]: slotObject })
    // console.log(value, week)
  }
  const slotArray = async (value, week) => {
    const slotObject = {
      timeSlot: value.value,
      week
    }
    setSlotsSubmitData({ ...slotsSubmitData, [week.timeperiod]: slotObject })
  }
  const handleFormSubmit = (values: ActivateAccountFormModel) => {
    const input = {
      token: params.token,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      picture: userImg,
      description: values.aboutMe ? values.aboutMe : profileBio?.userPicBio?.description,
      userAvailabilityInput: []
    }

    if (uploadStatus === 'Ready to upload' || uploadStatus === null)
      activateAccount({
        variables: {
          input: {
            token: params.token,
            password: values.password,
            passwordConfirmation: values.passwordConfirmation,
            picture: userImg,
            description: values.aboutMe ? values.aboutMe : aboutValue,
            userAvailabilityInput: []
          }
        }
      }).then(() => history.push('/login'))
    else setUploadError(true)
  }
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const renderForm = (renderProps: FormRenderProps<ActivateAccountFormModel>) => {
    const { handleSubmit } = renderProps

    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormRow>
              <TextField
                name="name"
                variant="filled"
                disabled
                fullWidth
                label="Full name"
                value={data?.userInvite?.name}
              />
            </FormRow>
            <FormRow>
              <TextField
                name="email"
                variant="filled"
                disabled
                fullWidth
                label="Email"
                value={data?.userInvite?.email}
              />
            </FormRow>
          </Grid>
          <Grid item xs={6} style={{ paddingRight: '35px' }}>
            <Paper>
              <div className={classes.imgContainer}>
                <Avatar
                  alt={data?.userInvite?.name}
                  className={classes.customAvatar}
                  src={userImg ? userImg : profileBio?.userPicBio?.picture}
                />
                {/* <img src={userImg || BlankImage} alt="USER" className={classes.previewImg} /> */}
                {/* <label className="custom-file-upload">
                  <i></i> Custom Upload
                </label> */}
                <div className={classes.upload} style={{ paddingLeft: '20px' }}>
                  <UploaderComponent
                    ref={uploadObj}
                    id="fileUpload"
                    name="imageUpload"
                    type="file"
                    style={{ border: 'none' }}
                    maxFileSize={500000}
                    allowedExtensions=".png, .jpg, .jpeg, .svg"
                    multiple={false}
                    change={(file) => imageUpload(file)}
                    showFileList={false}
                    autoUpload={false}
                    disabled={aboutDisable}
                    // uploading={(file) => upload(file)}
                    selected={(file) => imageUpload(file)}
                  />
                  {uploadError && (
                    <p
                      style={{
                        color: uploadStatus === 'Ready to upload' ? '#2ebb4c' : '#ff0000',
                        paddingLeft: '12px'
                      }}
                    >
                      {uploadStatus}
                    </p>
                  )}
                </div>
                {/* <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(value) => imageUpload(value)}
                  style={{ height: 'fit-content', padding: '50px 0px' }}
                /> */}
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <FormRow>
              <TextField
                name="aboutMe"
                variant="filled"
                fullWidth
                label="About Me"
                className={classes.texBox}
                value={aboutValue}
                defaultValue={aboutValue}
                disabled={aboutDisable}
                multiline
                rows={3}
              />
            </FormRow>
          </Grid>
          {/* <Grid item xs={12} style={{ padding: '1px 30px' }}>
            <span className="timeZoneTxt">
              <span>All times shown in Mountain(MT) </span> Hawaiian Time = -3 hrs, Alaskan Time =
              -2 hrs, Pacific Time = -1 hr, Central Time = +1 hr, Eastern Time = +2 hrs
            </span>
            <p className={classes.availableText} style={{ paddingLeft: 0 }}>
              Availability
            </p>
            <br />
          </Grid>
          <Grid container item xs={12} spacing={1}>
            {slotsDates?.nextDays?.map((day) => {
              return (
                <Grid item xs={6} style={{ padding: '5px 25px' }}>
                  <div style={{ display: 'flex' }}>
                    <Typography color="secondary" style={{ width: '150px' }}>
                      {day.timeperiod} &nbsp;
                      <div style={{ paddingLeft: '24px' }}>{day.dayname}</div>
                    </Typography>
                    <MultiSelectComponent
                      name={day.name}
                      dataSource={slotsDropData || []}
                      placeholder="select slots"
                      mode="Box"
                      showDropDownIcon
                      fields={fields}
                      change={(value) => slotArray(value, day)}
                      closePopupOnSelect={false}
                    />
                  </div>
                </Grid>
              )
            })}
          </Grid> */}
          <Grid item xs={6}>
            <FormRow>
              <TextField
                type={shownewPaswd ? 'text' : 'password'}
                name="password"
                variant="filled"
                fullWidth
                label="Password"
                // type="password"
                required
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className={classes.rightIcon} position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => toggleNewPaswd(!shownewPaswd)}
                        edge="end"
                      >
                        {shownewPaswd ? (
                          <Visibility style={{ color: 'slategray' }} />
                        ) : (
                          <VisibilityOff style={{ color: 'slategray' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormRow>
          </Grid>
          <Grid item xs={6}>
            <FormRow>
              <TextField
                type={shownewPaswd ? 'text' : 'password'}
                name="passwordConfirmation"
                variant="filled"
                fullWidth
                label="Re-enter password"
                // type="password"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment className={classes.rightIcon} position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => toggleNewPaswd(!shownewPaswd)}
                        edge="end"
                      >
                        {shownewPaswd ? (
                          <Visibility style={{ color: 'slategray' }} />
                        ) : (
                          <VisibilityOff style={{ color: 'slategray' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormRow>
          </Grid>
          <p className={classes.passWordText}>
            Password should contain 8 - 15 characters with at least one uppercase, lowercase,
            numeric and special character !{' '}
          </p>
        </Grid>

        <ButtonsRow>
          <Box display="flex">
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size="large"
              onClick={handleSubmit}
            >
              Sign up
            </Button>
          </Box>
        </ButtonsRow>
      </>
    )
  }

  return (
    <>
      {signuploading && <ProgressIndicator fullHeight />}
      <PaperHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h3" style={{ color: '#f1593a' }}>
            {welcomeMsg}
          </Typography>
          <InfoIcon
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <InfoPopOver />
            {/* <Typography>I use Popover.</Typography> */}
          </Popover>

          {/* <Icon className="fa fa-info-circle" color="primary" /> */}
        </div>

        <p className={classes.passWordText} style={{ paddingLeft: 0 }}>
          {welcomeSubTxt}
        </p>
      </PaperHeader>
      <Form<ActivateAccountFormModel>
        render={renderForm}
        onSubmit={handleFormSubmit}
        validate={validator}
        initialValues={initialValues}
      />
    </>
  )
}
