import React, { useState } from 'react'
import { Page, Text, Image, Document, StyleSheet, View, Link } from '@react-pdf/renderer'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { engLoc, spaLoc } from 'config/constants/translations'
import { useStyles } from './disclosureformstyle'

const BORDER_COLOR = 'black'
const styles = StyleSheet.create({
  body: {
    padding: '35px 35px 65px 35px'
  },
  title: {
    fontSize: 24,
    textAlign: 'center'
  },
  subTitle: {
    fontSize: 17,
    textAlign: 'left',
    marginBottom: 0,
    textDecoration: 'underline'
  },
  text: {
    // margin: 12,
    fontSize: 10,
    textAlign: 'justify'
  },
  bulletText: {
    margin: 12,
    fontSize: '17px'
  },
  bulletCircle: {
    textAlign: 'left',
    textAlignLeft: '10px',
    paddingLeft: '10px'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  table: {
    // display: "table",
    width: '100%',
    // borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    fontSize: '13px'
    // paddingRight: '200px'
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableCol1Header: {
    width: '35%',
    // borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: 'black',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColHeader: {
    width: '65%',
    // borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: 'black',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol1: {
    width: '35%',
    // borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '65%',
    // borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  textheader: {
    paddingTop: '15px',
    textDecoration: 'underline',
    fontWeight: 'bold',
    fontSize: '17px'
  },
  pl15: {
    paddingLeft: '15px',
    marginTop: '0px',
    marginBottom: '0px'
  },
  sectin: {
    padding: '3px 0px',
    fontSize: '17px'
  },
  regsectin: {
    padding: '10px 0px 10px 0px'
  },
  pb10: {
    fontSize: 10,
    textAlign: 'justify',
    paddingBottom: '10px'
  },
  pt5: {
    fontSize: 10,
    textAlign: 'justify',
    paddingTop: '4px'
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    flexGrow: 1
  },
  column: {
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1
  },
  left: {
    // paddingLeft: '10px',
    // paddingRight: '10px',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 300
  },
  right: {
    // paddingLeft: '10px',
    // paddingRight: '10px',
    padding: 5,
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: 300
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100
  }
})

interface MandatoryData {
  mandatoryDetails: any | undefined
  loc: string
}

export const MandatoryPdf = (props: MandatoryData) => {
  const { mandatoryDetails, loc } = props
  const { t, i18n } = useTranslation()
  const locallang = i18n?.language || 'en'
  const classes = useStyles()

  return (
    <Document>
      <Page style={styles.body} size="A4">
        {/* <Grid container className={classes.p50}> */}
        {/* <Grid item xs={12} sm={12} md={12}> */}
        {/* page title */}
        <Text style={styles.header} fixed>
          {loc === 'en' ? engLoc.mandatoryform.mdform : spaLoc.mandatoryform.mdform}
        </Text>
        {/* table display */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1Header}>
              <Text style={styles.tableCellHeader}>
                {loc === 'en' ? engLoc.mandatoryform.pcname : spaLoc.mandatoryform.pcname}
              </Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>{mandatoryDetails?.user_id?.name}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>
                {loc === 'en' ? engLoc.mandatoryform.lctext : spaLoc.mandatoryform.lctext}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{mandatoryDetails?.user_id?.license_number}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>
                {loc === 'en' ? engLoc.mandatoryform.school : spaLoc.mandatoryform.school}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {mandatoryDetails?.degreesheld_and_schoolattended}{' '}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>
                {loc === 'en'
                  ? engLoc.mandatoryform.degreeSchool
                  : spaLoc.mandatoryform.degreeSchool}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{mandatoryDetails?.school_attended} </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>
                {loc === 'en' ? engLoc.mandatoryform.aname : spaLoc.mandatoryform.aname}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {mandatoryDetails?.user_id?.signalAgencies?.map((v) => v?.name)}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>
                {loc === 'en' ? engLoc.mandatoryform.agency : spaLoc.mandatoryform.agency}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{mandatoryDetails?.user_id?.street_address}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>
                {loc === 'en' ? engLoc.mandatoryform.cphone : spaLoc.mandatoryform.cphone}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{mandatoryDetails?.clinician_phone}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>
                {loc === 'en' ? engLoc.mandatoryform.straining : spaLoc.mandatoryform.straining}{' '}
                {loc === 'en' ? engLoc.mandatoryform.straining1 : spaLoc.mandatoryform.straining1}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {mandatoryDetails?.specializedtraining_and_areasofclinicalcompetence}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>
                {loc === 'en' ? engLoc.mandatoryform.supervisor : spaLoc.mandatoryform.supervisor}{' '}
                {loc === 'en' ? engLoc.mandatoryform.supervisor1 : spaLoc.mandatoryform.supervisor1}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {mandatoryDetails?.supervisorname_and_credentials}
              </Text>
            </View>
          </View>
        </View>
        {/* about your service */}
        {/* subheading */}
        <View style={styles.sectin}>
          <Text style={styles.subTitle}>
            {loc === 'en' ? engLoc.mandatoryform.about : spaLoc.mandatoryform.about}
          </Text>
          {/* content */}
          {/* About Your Services */}
          <View style={{ flexDirection: 'column' }}>
            <Text>
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.bulletText}>{`\u2022 ${' '}`}</Text>
                <Text style={styles.text}>
                  {loc === 'en'
                    ? `${engLoc.mandatoryform.aboutT1}`
                    : `${spaLoc.mandatoryform.aboutT1}`}
                </Text>
              </View>
            </Text>
            <Text>
              <View style={{ flexDirection: 'row' }}>
                {' '}
                <Text style={styles.bulletText}>{`\u2022 ${' '}`}</Text>
                <Text style={styles.text}>
                  {loc === 'en'
                    ? `${engLoc.mandatoryform.aboutT2}`
                    : `${spaLoc.mandatoryform.aboutT2}`}
                </Text>
              </View>
            </Text>
            <Text>
              <View style={{ flexDirection: 'row' }}>
                {' '}
                <Text style={styles.bulletText}>{`\u2022 ${' '}`}</Text>
                <Text style={styles.text}>
                  {loc === 'en'
                    ? `${engLoc.mandatoryform.aboutT3}`
                    : `${spaLoc.mandatoryform.aboutT3}`}
                </Text>
              </View>
            </Text>
          </View>
        </View>
        {/* Confidentiality */}
        <View style={styles.sectin}>
          <Text style={styles.subTitle}>
            {loc === 'en'
              ? engLoc.mandatoryform.confidentiality
              : spaLoc.mandatoryform.confidentiality}
          </Text>
          <Text>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.bulletText}>{`\u2022 ${' '}`}</Text>
              <Text style={styles.text}>
                {loc === 'en'
                  ? engLoc.mandatoryform.confidentialityT1
                  : spaLoc.mandatoryform.confidentialityT1}
              </Text>
            </View>
          </Text>
          <Text style={{ paddingTop: '5px', paddingBottom: '10px' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.bulletText}>{`\u2022 ${' '}`}</Text>
              <Text style={styles.text}>
                {loc === 'en'
                  ? engLoc.mandatoryform.confidentialityT2
                  : spaLoc.mandatoryform.confidentialityT2}
              </Text>
            </View>
          </Text>
          <View
            style={{
              flexDirection: 'row',
              display: 'flex',
              paddingBottom: '5px',
              paddingLeft: '25px'
            }}
          >
            <Text style={{ fontSize: '12px' }}>{`\u2022 ${' '}`}</Text>
            <Text style={styles.text}>
              {loc === 'en' ? engLoc.mandatoryform.cbp1 : spaLoc.mandatoryform.cbp1}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              display: 'flex',
              paddingBottom: '5px',
              paddingLeft: '25px'
            }}
          >
            <Text style={{ fontSize: '12px' }}>{`\u2022 ${' '}`}</Text>
            <Text style={styles.text}>
              {loc === 'en' ? engLoc.mandatoryform.cbp2 : spaLoc.mandatoryform.cbp2}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              display: 'flex',
              paddingBottom: '5px',
              paddingLeft: '25px'
            }}
          >
            <Text style={{ fontSize: '12px' }}>{`\u2022 ${' '}`}</Text>
            <Text style={styles.text}>
              {loc === 'en' ? engLoc.mandatoryform.cbp3 : spaLoc.mandatoryform.cbp3}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              display: 'flex',
              paddingBottom: '5px',
              paddingLeft: '25px'
            }}
          >
            <Text style={{ fontSize: '12px' }}>{`\u2022 ${' '}`}</Text>
            <Text style={styles.text}>
              {loc === 'en' ? engLoc.mandatoryform.cbp4 : spaLoc.mandatoryform.cbp4}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              display: 'flex',
              paddingBottom: '5px',
              paddingLeft: '25px'
            }}
          >
            <Text style={{ fontSize: '12px' }}>{`\u2022 ${' '}`}</Text>
            <Text style={styles.text}>
              {loc === 'en' ? engLoc.mandatoryform.cbp5 : spaLoc.mandatoryform.cbp1}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              display: 'flex',
              paddingBottom: '5px',
              paddingLeft: '25px'
            }}
          >
            <Text style={{ fontSize: '12px' }}>{`\u2022 ${' '}`}</Text>
            <Text style={styles.text}>
              {loc === 'en' ? engLoc.mandatoryform.cbp6 : spaLoc.mandatoryform.cbp6}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', display: 'flex', paddingLeft: '25px' }}>
            <Text style={{ fontSize: '12px' }}>{`\u2022 ${' '}`}</Text>
            <Text style={styles.pb10}>
              {loc === 'en' ? engLoc.mandatoryform.cbp7 : spaLoc.mandatoryform.cbp7}
            </Text>
          </View>
          <Text>
            <View style={{ flexDirection: 'row', display: 'flex' }}>
              <Text style={styles.bulletText}>{`\u2022 ${' '}`}</Text>
              <Text style={styles.text}>
                {loc === 'en'
                  ? engLoc.mandatoryform.confidentialityT3
                  : spaLoc.mandatoryform.confidentialityT3}
              </Text>
            </View>
          </Text>
        </View>
        {/* questions */}
        <View style={styles.sectin}>
          <Text style={styles.subTitle}>
            {loc === 'en' ? engLoc.mandatoryform.qc : spaLoc.mandatoryform.qc}
          </Text>
          <Text style={styles.pt5}>
            {loc === 'en' ? engLoc.mandatoryform.qrc1 : spaLoc.mandatoryform.qrc1}{' '}
            <Link src="mailto: support@imattercolorado.org">support@imattercolorado.org</Link>.{' '}
            {loc === 'en' ? engLoc.mandatoryform.qrc2 : spaLoc.mandatoryform.qrc2}
          </Text>
        </View>
        {/* regularity */}
        <View style={styles.regsectin}>
          <Text style={styles.subTitle}>
            {loc === 'en' ? engLoc.mandatoryform.regulatory : spaLoc.mandatoryform.regulatory}
          </Text>
          <Text style={styles.pt5}>
            {loc === 'en' ? engLoc.mandatoryform.regtext : spaLoc.mandatoryform.regtext}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.left}>
            <Text
              style={[
                styles.text,
                { paddingLeft: '20px', paddingRight: '5px', paddingBottom: '5px' }
              ]}
            >
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.lcsw : spaLoc.mandatoryform.lcsw}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.lcswT : spaLoc.mandatoryform.lcswT}
            </Text>
            <Text
              style={[
                styles.text,
                { paddingLeft: '20px', paddingRight: '5px', paddingBottom: '5px' }
              ]}
            >
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.lmft : spaLoc.mandatoryform.lmft}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.lmftT : spaLoc.mandatoryform.lmftT}
            </Text>
            <Text
              style={[
                styles.text,
                { paddingLeft: '20px', paddingRight: '5px', paddingBottom: '5px' }
              ]}
            >
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.lpc : spaLoc.mandatoryform.lpc}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.lpcT : spaLoc.mandatoryform.lpcT}
            </Text>
            <Text
              style={[
                styles.text,
                { paddingLeft: '20px', paddingRight: '5px', paddingBottom: '5px' }
              ]}
            >
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.lp : spaLoc.mandatoryform.lp}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.lpT : spaLoc.mandatoryform.lpT}
            </Text>
            <Text
              style={[
                styles.text,
                { paddingLeft: '20px', paddingRight: '5px', paddingBottom: '5px' }
              ]}
            >
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.lsw : spaLoc.mandatoryform.lsw}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.lswT : spaLoc.mandatoryform.lswT}
            </Text>
            <Text
              style={[
                styles.text,
                { paddingLeft: '20px', paddingRight: '5px', paddingBottom: '5px' }
              ]}
            >
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.psycologist : spaLoc.mandatoryform.psycologist}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.psycologistT : spaLoc.mandatoryform.psycologistT}
            </Text>
          </View>
          <View style={[styles.right, { paddingRight: '20px', paddingLeft: '5px' }]}>
            <Text style={[styles.text, { paddingBottom: '5px' }]}>
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.cat : spaLoc.mandatoryform.cat}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.catT : spaLoc.mandatoryform.catT}
            </Text>
            <Text style={[styles.text, { paddingBottom: '5px' }]}>
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.cas : spaLoc.mandatoryform.cas}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.casT : spaLoc.mandatoryform.casT}
            </Text>
            <Text style={[styles.text, { paddingBottom: '5px' }]}>
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.lac : spaLoc.mandatoryform.lac}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.lacT : spaLoc.mandatoryform.lacT}
            </Text>
            <Text style={[styles.text, { paddingBottom: '5px' }]}>
              {loc === 'en' ? engLoc.mandatoryform.A : spaLoc.mandatoryform.A}{' '}
              <Text style={{ fontSize: '11px' }}>
                {loc === 'en' ? engLoc.mandatoryform.unlicensed : spaLoc.mandatoryform.unlicensed}
              </Text>{' '}
              {loc === 'en' ? engLoc.mandatoryform.unlicensedT : spaLoc.mandatoryform.unlicensedT}
            </Text>
          </View>
        </View>
        <View style={[styles.row, styles.sectin]}>
          <View style={{ flexGrow: 1, flexBasis: 'auto' }}>
            <Text style={[styles.subTitle, { paddingBottom: '3px' }]}>
              {loc === 'en' ? engLoc.mandatoryform.ack : spaLoc.mandatoryform.ack}
            </Text>
            <Text style={styles.pt5}>
              {loc === 'en' ? engLoc.mandatoryform.ackT1 : spaLoc.mandatoryform.ackT1}
            </Text>
            <Text style={styles.pt5}>
              {loc === 'en' ? engLoc.mandatoryform.ackT2 : spaLoc.mandatoryform.ackT2}
            </Text>
            <View style={[styles.row, { paddingTop: '5px' }]}>
              <View style={styles.left}>
                <View style={{ height: '40px' }}>
                  <Text
                    style={[
                      styles.text,
                      {
                        padding: '5px 5px 5px 0px',
                        width: '100%',
                        borderBottom: '1px solid grey'
                      }
                    ]}
                  >
                    {mandatoryDetails?.student_id?.name}
                  </Text>
                  <Text style={[styles.text, { padding: '5px 5px 5px 0px' }]}>
                    {loc === 'en' ? engLoc.mandatoryform.cname : spaLoc.mandatoryform.cname}
                  </Text>
                </View>
                <View style={{ height: '60px' }}>
                  <Text
                    style={[
                      styles.text,
                      {
                        padding: '5px 5px 5px 0px',
                        width: '100%',
                        borderBottom: '1px solid grey'
                      }
                    ]}
                  >
                    <Image
                      style={{ height: '20px', width: '60px' }}
                      src={mandatoryDetails?.student_sign}
                    />
                  </Text>
                  <Text style={[styles.text, { padding: '5px 5px 5px 0px' }]}>
                    {loc === 'en'
                      ? engLoc.mandatoryform.crpsignature
                      : spaLoc.mandatoryform.crpsignature}
                  </Text>
                </View>
                <View style={{ height: '40px' }}>
                  <Text
                    style={[
                      styles.text,
                      {
                        padding: '5px 5px 5px 0px',
                        width: '100%',
                        borderBottom: '1px solid grey'
                      }
                    ]}
                  >
                    {moment(mandatoryDetails?.student_date).format('MM/DD/YYYY')}
                  </Text>
                  <Text style={[styles.text, { padding: '5px 5px 5px 0px' }]}>
                    {loc === 'en' ? engLoc.mandatoryform.dt : spaLoc.mandatoryform.dt}
                  </Text>
                </View>
                <View style={{ height: '40px' }}>
                  <Text
                    style={[
                      styles.text,
                      {
                        padding: '5px 5px 5px 0px',
                        width: '100%',
                        borderBottom: '1px solid grey'
                      }
                    ]}
                  >
                    {mandatoryDetails?.user_id?.name}
                  </Text>
                  <Text style={[styles.text, { padding: '5px 5px 5px 0px' }]}>
                    {loc === 'en'
                      ? engLoc.mandatoryform.pcnprinted
                      : spaLoc.mandatoryform.pcnprinted}
                  </Text>
                </View>
                <View style={{ height: '60px' }}>
                  <Text
                    style={[
                      styles.text,
                      {
                        padding: '5px 5px 5px 0px',
                        width: '100%',
                        borderBottom: '1px solid grey'
                      }
                    ]}
                  >
                    <Image
                      style={{ height: '20px', width: '60px' }}
                      src={mandatoryDetails?.user_sign}
                    />
                  </Text>
                  <Text style={[styles.text, { padding: '5px 5px 5px 0px' }]}>
                    {loc === 'en'
                      ? engLoc.mandatoryform.pcsignature
                      : spaLoc.mandatoryform.pcsignature}
                  </Text>
                </View>
              </View>
              <View style={[styles.right]}>
                <View style={{ height: '40px' }}>
                  <Text
                    style={[
                      styles.text,
                      {
                        padding: '2px 5px 0px 0px',
                        width: '100%',
                        borderBottom: '1px solid grey'
                      }
                    ]}
                  >
                    {mandatoryDetails?.relationship_to_client_id?.name}
                  </Text>
                  <Text style={[styles.text, { padding: '5px 5px 5px 0px' }]}>
                    {loc === 'en' ? engLoc.mandatoryform.rtoc : spaLoc.mandatoryform.rtoc}
                  </Text>
                </View>
                <View style={{ marginTop: '140px', height: '40px' }}>
                  <Text
                    style={[
                      styles.text,
                      {
                        padding: '0px 5px 25px 0px',
                        width: '100%',
                        borderBottom: '1px solid grey'
                      }
                    ]}
                  >
                    {moment(mandatoryDetails?.student_date).format('MM/DD/YYYY')}
                  </Text>
                  <Text style={[styles.text, { padding: '5px 5px 5px 0px' }]}>
                    {loc === 'en' ? engLoc.mandatoryform.dt : spaLoc.mandatoryform.dt}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        />
      </Page>
    </Document>
  )
}
