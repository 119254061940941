import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  ToolbarItems,
  ExcelExport,
  Grid,
  Inject,
  Toolbar,
  Page,
  ExcelExportProperties,
  SortSettingsModel,
  Sort
} from '@syncfusion/ej2-react-grids'
import React, { useMemo, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { Typography, Link, MenuItem, Select } from '@material-ui/core'
import { theme } from 'config/constants/theme'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import { useStyles } from 'config/constants/theme/profile'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { PageContainer } from '../../../../components/ui/panel/page-container/PageContainer'
import {
  Role,
  useCallqualityreportLazyQuery,
  useCurrentUserQuery,
  useProviderNameListLazyQuery,
  useTherapistListForDocLazyQuery
} from '../../../../graphql/hooks.generated'
// import { useStyles } from '../../../../config/constants/theme/profile'

export const Callqualityreport = () => {
  const history = useHistory()
  const location = useLocation()
  const { state }: any = location
  let grid: Grid | null
  const toolbar: ToolbarItems[] = ['ExcelExport']
  const [reportQuery, { data, loading }] = useCallqualityreportLazyQuery({
    fetchPolicy: 'network-only'
  })
  // const last24HoursStart = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
  // const last24HoursEnd = moment().format('YYYY-MM-DD HH:mm:ss')
  // const lastOneWeek = moment().subtract(7, 'days').format('YYYY-MM-DD')
  // const currentDate = moment().format('YYYY-MM-DD')
  const [billfrom, BillFrom] = useState<any>()
  const [billTo, BillTo] = useState<any>()
  const filteredRecords = data?.callqualityreport.filter(
    (record) => record.provider_comment || record.client_comment
  )
  useEffect(() => {
    const mst = moment.tz('America/Denver')
    const lastOneWeek = moment().subtract(6, 'days').format('YYYY-MM-DD')
    const currentDate = moment().format('YYYY-MM-DD')
    const last24HoursStart = moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss')
    const last24HoursEnd = moment().format('YYYY-MM-DD HH:mm:ss')

    if (state?.callqualityData) {
      BillFrom(last24HoursStart)
      BillTo(last24HoursEnd)
    } else {
      BillFrom(lastOneWeek)
      BillTo(currentDate)
    }
  }, [state, reportQuery])
  const classes = useStyles()
  const [providername, setProvidername] = useState('')
  const [userid, Userid] = useState()
  const { hasAnyRole } = useCurrentUser()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const [
    executeThepistQuery,
    { data: therapistData, loading: therapistDocumentLoading }
  ] = useProviderNameListLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      agency: String(
        currentUserdata?.data?.currentUser?.signalAgencies &&
          currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          ? currentUserdata?.data?.currentUser?.signalAgencies[0]?.name
          : 0
      )
    }
  })
  const [executeThepistQuerys, { data: therapistDataAll }] = useTherapistListForDocLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      district_id: Number(currentUserdata?.data?.currentUser?.district?.id)
    }
  })
  const fetchTherapistsall = useMemo(() => () => executeThepistQuerys(), [executeThepistQuerys])
  const fetchTherapists = useMemo(() => () => executeThepistQuery(), [executeThepistQuery])
  useEffect(() => {
    if (userid === 'All') fetchTherapistsall()
  }, [userid, fetchTherapistsall])
  useEffect(() => {
    fetchTherapistsall()
    fetchTherapists()
  }, [fetchTherapists, fetchTherapistsall])
  useEffect(() => {
    if (therapistData && currentUserdata) {
      const provider = therapistData?.providerName?.[0]?.provider_id
      const currentprovider = currentUserdata?.data?.currentUser?.roles?.[0]
      setProvidername(
        currentprovider === Role.AgencySupervisorProvider
          ? currentUserdata?.data?.currentUser?.id || ''
          : 'All' || ''
      )
    }
  }, [currentUserdata, therapistData, therapistData?.providerName])
  const providerchange = (e) => {
    setProvidername(e.target.value)
  }
  const FromDate = (e) => {
    BillFrom(e.target.value)
  }
  const ToDate = (e) => {
    BillTo(e.target.value)
  }
  const useridone = (e) => {
    Userid(e.target.value)
  }
  const sortSettings: SortSettingsModel = {
    columns: [
      { field: 'agency', direction: 'Ascending' },
      { field: 'providername', direction: 'Ascending' }
    ]
  }
  const Fromformat = moment(billfrom).format('MM/DD/YYYY')
  const Toformat = moment(billTo).format('MM/DD/YYYY')
  const toolbarClick = (args) => {
    if (grid && args.item.id === 'grid_excelexport') {
      const excelExportProperties: ExcelExportProperties = {
        fileName: `CallQualityReport(${billfrom}_${billTo}).xlsx`,
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: 4,
                  value:
                    state === undefined
                      ? 'Call Quality Feedback Report(Last 7 Days)'
                      : 'Call Quality Feedback Report(Last 24 hours)',
                  style: { fontSize: 15, bold: true }
                }
              ]
            },
            {
              cells: [
                {
                  colSpan: 4,
                  value: `From Date: ${Fromformat}`,
                  style: { fontSize: 13, bold: true }
                }
              ]
            },
            {
              cells: [
                {
                  colSpan: 4,
                  value: `To Date: ${Toformat}`,
                  style: { fontSize: 13, bold: true }
                }
              ]
            }
          ]
        }
      }
      grid.excelExport(excelExportProperties)
    }
  }
  const reportquery = useMemo(
    () => ({ from_date, to_date, user_id, agency_id }) =>
      reportQuery({
        variables: { from_date, to_date, user_id, agency_id }
      }),
    [reportQuery]
  )
  const provider = hasAnyRole(Role.AgencySupervisor, Role.AgencySupervisorProvider)
  const agency = String(currentUserdata?.data?.currentUser?.signalAgencies?.[0]?.id)
  useEffect(() => {
    if (billfrom && billTo) {
      const input = {
        from_date: billfrom,
        to_date: billTo,
        user_id: provider ? providername : userid,
        agency_id: provider ? agency : ''
      }
      reportquery(input)
    }
  }, [billfrom, billTo, reportquery, providername, userid, provider, agency])
  const gridDataSource =
    state?.issue && filteredRecords?.length > 0 ? filteredRecords : data?.callqualityreport || []

  return (
    <>
      <PageContainer>
        <div style={{ display: 'flex' }}>
          <Typography variant="h3" style={{ color: theme.palette.info.main, padding: '12px' }}>
            Call Quality Report
          </Typography>
        </div>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              textAlign: 'end',
              position: 'absolute',
              top: '21px',
              right: '10px',
              zIndex: 99,
              fontSize: '16px',
              lineHeight: 'normal'
            }}
          >
            {/* {hasAnyRole(Role.AgencySupervisorProvider, Role.AgencySupervisor) && (
              <span>
                <b style={{ fontSize: '16px' }}>Provider:</b>{' '}
                <Select
                  style={{
                    width: '150px',
                    height: '32px',
                    marginRight: '8px',
                    textAlign: 'initial'
                  }}
                  variant="filled"
                  value={providername}
                  className={classes.selectDropdown}
                  onChange={providerchange}
                  classes={{
                    root: 'muisc-root',
                    select: 'muisc-select',
                    selectMenu: 'muisc-select-menu'
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {therapistData?.providerName && therapistData?.providerName?.length > 0 ? (
                    therapistData?.providerName?.map((listTherapists) => (
                      <MenuItem
                        key={listTherapists.provider_id?.toString()}
                        value={listTherapists.provider_id?.toString()}
                        style={{
                          maxWidth: '280px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {listTherapists.provider_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="No Data Found">No Data Found</MenuItem>
                  )}
                </Select>
              </span>
            )} */}
            &nbsp;
            {(hasAnyRole(Role.SchoolAdmin) || hasAnyRole(Role.TeleteachersAdmin)) && (
              <span>
                <b> Provider:</b>
                &nbsp;
                <Select
                  style={{
                    width: '150px',
                    height: '32px',
                    marginRight: '9px',
                    textAlign: 'initial'
                  }}
                  className={classes.selectDropdown}
                  defaultValue={userid}
                  variant="filled"
                  onChange={useridone}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 350 // Set the max height you want
                      }
                    }
                  }}
                  classes={{
                    root: 'muisc-root',
                    select: 'muisc-select',
                    selectMenu: 'muisc-select-menu'
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                  {therapistDataAll?.therapistListForDoc &&
                  therapistDataAll?.therapistListForDoc?.length > 0 ? (
                    therapistDataAll?.therapistListForDoc?.map((listTherapists) => (
                      <MenuItem
                        key={listTherapists.id?.toString()}
                        placeholder="ALL"
                        value={listTherapists.id?.toString()}
                      >
                        {listTherapists.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="No Data Found">No Data Found</MenuItem>
                  )}
                </Select>
              </span>
            )}
            &nbsp;
            <span>
              {' '}
              <b> From Date:</b>{' '}
              <TextField
                style={{ border: '1px solid #000' }}
                name="FromDate"
                type="date"
                onChange={FromDate}
                // value={billfrom}
                value={moment(billfrom).format('YYYY-MM-DD')}
              />
            </span>{' '}
            &nbsp;
            <span>
              {' '}
              <b>To Date:</b>{' '}
              <TextField
                style={{ border: '1px solid #000' }}
                name="ToDate"
                type="date"
                onChange={ToDate}
                // value={billTo}
                value={moment(billTo).format('YYYY-MM-DD')}
              />
            </span>
          </div>
          {loading && <ProgressIndicator fullHeight />}
          <GridComponent
            dataSource={gridDataSource}
            allowTextWrap
            allowPaging
            pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
            allowSorting
            id="grid"
            toolbar={toolbar}
            allowExcelExport
            toolbarClick={(e) => toolbarClick(e)}
            ref={(g) => {
              grid = g
            }}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="agency"
                headerText="Agency"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="providername"
                headerText="Provider Name"
                width="180"
                allowFiltering={false}
              />
              <ColumnDirective
                field="client"
                template={(report) => (
                  <Link
                    onClick={(e) => {
                      history.push({
                        pathname: `/studentsinfo/${btoa(
                          `${report?.student_id}_${report?.student_id}`
                        )}`,
                        state: report?.provider_id
                      })
                    }}
                    style={{ color: 'black', cursor: 'pointer' }}
                  >
                    <span>{`${report?.client}`}</span>
                  </Link>
                )}
                headerText="Client"
                width="120"
              />
              <ColumnDirective
                field="client_id"
                headerText="Client Id"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective field="city" headerText="City" width="120" allowFiltering={false} />

              <ColumnDirective field="Date" headerText="Date" width="120" allowFiltering={false} />
              {/* <ColumnDirective
                field="scheduled_start"
                headerText="Scheduled_start"
                width="200"
                allowFiltering={false}
              /> */}
              <ColumnDirective
                field="fromtime"
                headerText="From Time"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="totime"
                headerText="To Time"
                width="120"
                allowFiltering={false}
              />
              <ColumnDirective
                field="client_seeing_rate"
                headerText="Client Saw Provider"
                width="200"
                allowFiltering={false}
              />
              <ColumnDirective
                field="client_listening_rate"
                headerText="Client Heard Provider"
                width="200"
                allowFiltering={false}
              />
              <ColumnDirective
                field="client_comment"
                headerText="Client Response"
                width="250"
                allowFiltering={false}
                template={(report) => (
                  <div className={classes.truncatetext} title={report.client_comment}>
                    {report.client_comment}
                  </div>
                )}
              />

              <ColumnDirective
                field="provider_seeing_rate"
                headerText="Provider Saw Client"
                width="200"
                allowFiltering={false}
              />
              <ColumnDirective
                field="provider_listening_rate"
                headerText="Provider Heard Client"
                width="200"
                allowFiltering={false}
              />
              <ColumnDirective
                field="provider_comment"
                headerText="Provider Response"
                width="200"
                allowFiltering={false}
                template={(report) => (
                  <div className={classes.truncatetext} title={report.provider_comment}>
                    {report.provider_comment}
                  </div>
                )}
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, ExcelExport, Page, Sort]} />
          </GridComponent>
        </div>
      </PageContainer>
    </>
  )
}
