import React, { useEffect, useMemo } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  TableHead,
  TextareaAutosize
} from '@material-ui/core'
import {
  useCallfeedback24hrcountQuery,
  useCallfeedback7DaysCountQuery
} from 'graphql/hooks.generated'
import { Link } from 'react-router-dom'
import ProgressIndicator from '../../../../components/ui/panel/progress-indicator/ProgressIndicator'
import { ReactComponent as AttachIcon } from '../../../assets/icons/attach.svg'
import useStyles from '../Styles'

export default function Callquality() {
  const classes = useStyles()
  const { data: callqualityData, loading: requestsLoading } = useCallfeedback24hrcountQuery({
    fetchPolicy: 'network-only'
  })
  const { data: callfeedback7days, loading: call7daysLoadings } = useCallfeedback7DaysCountQuery({
    fetchPolicy: 'network-only'
  })

  return (
    <div>
      {callqualityData && (
        <>
          <Paper style={{ padding: '25px' }}>
            <div>
              <h3 className={classes.h4}>
                <u>Call Quality Feedback Responses </u>
              </h3>
              <TableContainer component={Paper}>
                <Table stickyHeader className={classes.tableBorder} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>In the Last 24 Hours</TableCell>
                      <TableCell>Total Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        Total Number of Sessions
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        {callqualityData?.callfeedback24hrcount?.[0]?.Appointments
                          ? callqualityData.callfeedback24hrcount[0].Appointments
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        Sessions with Responses
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        {callqualityData?.callfeedback24hrcount?.[0]?.TotalResponse ? (
                          <Link
                            style={{ color: 'black' }}
                            to={{
                              pathname: '/reports/Callquality-report',
                              state: {
                                callqualityData:
                                  callqualityData.callfeedback24hrcount[0].Appointments
                              }
                            }}
                          >
                            {callqualityData?.callfeedback24hrcount?.[0]?.TotalResponse}
                          </Link>
                        ) : (
                          0
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" style={{ fontWeight: 'bold', borderBottom: 'none' }}>
                        Sessions with Reported Issues
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        {callqualityData?.callfeedback24hrcount?.[0]?.TotalIssues ? (
                          <Link
                            style={{ color: 'black' }}
                            to={{
                              pathname: '/reports/Callquality-report',
                              state: {
                                callqualityData:
                                  callqualityData.callfeedback24hrcount[0].Appointments,
                                issue: 'issue24'
                              }
                            }}
                          >
                            {callqualityData?.callfeedback24hrcount?.[0]?.TotalIssues}
                          </Link>
                        ) : (
                          0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div>
              <h3 className={classes.h4}>
                {/* <u>Call Quality Feedback Responses in the Last 7 Days</u> */}
              </h3>
              <TableContainer component={Paper}>
                <Table stickyHeader className={classes.tableBorder} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>In the Last 7 Days</TableCell>
                      <TableCell>Total Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        Total Number of Sessions
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        {callfeedback7days?.callfeedback7DaysCount?.[0]?.Appointments
                          ? callfeedback7days?.callfeedback7DaysCount?.[0]?.Appointments
                          : 0}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        Sessions with Responses
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        {callfeedback7days?.callfeedback7DaysCount?.[0]?.TotalResponse ? (
                          <Link style={{ color: 'black' }} to="/reports/Callquality-report">
                            {callfeedback7days?.callfeedback7DaysCount?.[0]?.TotalResponse}
                          </Link>
                        ) : (
                          0
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        Sessions with Reported Issues
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        {callfeedback7days?.callfeedback7DaysCount?.[0]?.TotalIssues ? (
                          <Link
                            style={{ color: 'black' }}
                            to={{
                              pathname: '/reports/Callquality-report',
                              state: {
                                issue: 'issue24'
                              }
                            }}
                          >
                            {callfeedback7days?.callfeedback7DaysCount?.[0]?.TotalIssues}
                          </Link>
                        ) : (
                          0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className={classes.requestProgress}>
              {requestsLoading && <ProgressIndicator position="relative" top="-60px" />}
            </div>
          </Paper>
        </>
      )}
    </div>
  )
}
