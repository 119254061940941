import React, { useRef, useEffect, useMemo } from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import { NotificationsActiveSharp } from '@material-ui/icons'
import { useCurrentUserQuery } from 'graphql/hooks.generated'
import useStyles from './Styles'
import AdminActivity from './admin/activity'
import Requests from './therapist/requests'
import { DashboardScheduer } from '../TherapistScheduler'
import { Chart } from './therapist/echarts'
import AutoGrid from './therapist/Grids'
import AdminAutoGrid from './admin/grids'
import { AdminChart } from './admin/echarts'
import Callquality from './admin/callquality'
// import { CallqualityGraph } from './admin/callqualitygraph'

export const MainDashboard = () => {
  const classes = useStyles()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })

  return (
    <div>
      <Grid container>
        <Grid item xs={7} className={classes.leftContainer}>
          <div className={classes.dataCls}>
            <Grid item xs={12} md={12}>
              <Paper className={classes.paperNewStyle1}>
                <Grid xs={2} md={1} className={classes.headingClsNew}>
                  <NotificationsActiveSharp className={classes.alertIcon} />
                </Grid>
                <Grid
                  xs={10}
                  md={11}
                  className={classes.headingClsNew1}
                  style={{ textAlign: 'left' }}
                >
                  {currentUserdata && (
                    <span>Welcome back, {currentUserdata?.data?.currentUser?.name}!</span>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.calenderCls}>
              {/* <Paper className={classes.chart}> */}
              <AdminChart />
              {/* </Paper> */}
            </Grid>
            <Grid item xs={12} className={classes.calenderCls}>
              <Paper className={classes.paperNew}>
                <Grid item xs={12} className={classes.tableProvideCls}>
                  <Callquality />
                </Grid>
              </Paper>
            </Grid>
            {/* <Grid item xs={12} className={classes.calenderCls}>
              <Paper className={classes.chart}>
                <CallqualityGraph />
              </Paper>
            </Grid> */}
          </div>
        </Grid>
        <Grid item xs={5} className={classes.rightContainer}>
          <Grid item xs={12} className={classes.rightCls1}>
            <AdminAutoGrid />
            <Paper className={classes.paperNew}>
              <Grid item xs={12} className={classes.lookUpCls}>
                <AdminActivity />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
