import React, { useState, useMemo, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { Grid } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import { useLocation, useParams } from 'react-router-dom'
import {
  useAppointmentProfileQuery,
  useAppointmentProfileCountQuery,
  useStudentAppointmentMetricsQuery,
  useCurrentUserQuery,
  Role
  // useStudentAppointmentMetricsQuery
} from 'graphql/hooks.generated'
import { UseParamsType } from 'types'
import Tooltip from '@material-ui/core/Tooltip'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import moment from 'moment'
import { useCurrentUser } from 'graphql/hooks/useCurrentUser/useCurrentUser'
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  FilterSettingsModel,
  Inject,
  Filter,
  Search,
  Sort,
  Page,
  DetailRow
} from '@syncfusion/ej2-react-grids'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'
import { useStyles } from '../../../../../config/constants/theme/profile'
import { ReactComponent as Question } from '../../../../../assets/icons/questionmark.svg'

interface ListViewParams extends UseParamsType {
  stu_id: string
}

interface Parentparams {
  studentDetails: any
}

const AppointmentInfo = (props: Parentparams) => {
  const classes = useStyles()
  const location = useLocation()
  const { hasRole, user } = useCurrentUser()
  const [providername, setProvidername] = useState('')
  const { studentDetails } = props
  const { stu_id } = useParams<ListViewParams>()
  let stud_id: any
  let decode1
  let decode2
  try {
    ;[decode1, decode2] = atob(stu_id).split('_')
  } catch (e) {
    // console.log(e)
  }

  if (decode1 && decode2 && decode1 === decode2) stud_id = decode1

  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]
  useEffect(() => {
    const rolesName = currentUserdata?.data?.currentUser?.roles?.[0]
    const currentuser_provider =
      (rolesName === Role.Therapist ||
        rolesName === Role.SchoolTherapist ||
        rolesName === Role.AgencySupervisorProvider) &&
      currentUserdata?.data?.currentUser?.id

    if (rolesName === Role.AgencySupervisor || rolesName === Role.AgencySupervisorProvider)
      setProvidername((location.state as string) || (currentuser_provider as string))
    else setProvidername(currentuser_provider || '')
  }, [currentUserdata, location.state])
  const { data, loading, refetch } = useAppointmentProfileQuery({
    fetchPolicy: 'network-only',
    variables: { id: Number(stud_id), user_id: providername },
    skip:
      rolesName === Role.SchoolAdmin || rolesName === Role.TeleteachersAdmin ? false : !providername
  })
  const { data: studentsData } = useStudentAppointmentMetricsQuery({
    fetchPolicy: 'network-only',
    variables: { student_id: Number(stud_id), user_id: providername },
    skip:
      rolesName === Role.SchoolAdmin || rolesName === Role.TeleteachersAdmin ? false : !providername
  })
  const isLoading = () => (loading ? <ProgressIndicator fullHeight /> : '')
  const filterOption: FilterSettingsModel = {
    ignoreAccent: true,
    type: 'Excel'
  }
  const provider = (seeing: any, listeing: any, comment: any) => {
    return (
      <>
        <span>PROVIDER FEEDBACK</span>
        <br />
        <br />
        <span>Could you hear the client throughout the session?</span>
        <br />
        <span>{listeing}</span>
        <br /> <br />
        <span>Could you see the client throughout the session?</span>
        <br />
        <span>{seeing}</span>
        <br />
        <br />
        {comment && (
          <>
            <span>Please explain what happened as far as the video/audio quality:</span>
            <br />
            <span> {comment}</span>
            <br />
            <br />
          </>
        )}
      </>
    )
  }
  const client = (seeing: any, listeing: any, comment: any) => {
    return (
      <>
        <span>CLIENT FEEDBACK</span>
        <br />
        <br />
        <span>Could you hear the provider throughout the session?</span>
        <br />
        <span>{listeing}</span>
        <br /> <br />
        <span>Could you see the provider throughout the session?</span>
        <br />
        <span>{seeing}</span>
        <br />
        <br />
        {comment && (
          <>
            <span>Please explain what happened as far as the video/audio quality:</span>
            <br />
            <span> {comment}</span>
            <br />
            <br />
          </>
        )}
      </>
    )
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.namecard}>
            <div>
              <Avatar style={{ float: 'left' }} className={classes.avatar}>
                {studentDetails &&
                  studentDetails?.name?.split(/[\s]+/).map((value) => value.slice(0, 1))}
              </Avatar>
              <div style={{ float: 'left', padding: '10px' }}>
                {studentDetails && (
                  <>
                    <span style={{ fontWeight: 'bold', color: '#F1583A', fontSize: '22px' }}>
                      {studentDetails?.name}
                    </span>
                    <div>
                      {moment().diff(moment(studentDetails?.birth_date), 'years', false)} years old
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={classes.session}>
              <div>
                <p className={classes.blueback}>
                  Total Booked Appointments:
                  <strong className={classes.bluelabel}>
                    {/* {studentsData &&
                        (item, index) =>
                          index === 0 && <span>{item?.totalBookedAppointments} </span>
                      )} */}
                    {!studentsData
                      ? 0
                      : studentsData?.studentAppointmentMetrics?.totalBookedAppointments}
                  </strong>
                </p>
              </div>
            </div>
            <div className={classes.session}>
              <div>
                <p className={classes.blueback}>
                  Total Sessions Attended:
                  <strong className={classes.bluelabel}>
                    {/* {studentsData &&
                      studentsData?.studentAppointmentMetrics?.map(
                        (item, index) => index === 0 && <span>{item?.totalSessionsAttended} </span>
                      )} */}
                    {!studentsData
                      ? 0
                      : studentsData?.studentAppointmentMetrics?.totalSessionsAttended}
                  </strong>
                </p>
              </div>
            </div>
            <div className={classes.session}>
              <div>
                <p className={classes.blueback}>
                  Additional Sessions Approved:
                  <strong className={classes.bluelabel}>
                    {/* {studentsData &&
                      studentsData?.studentAppointmentMetrics.map(
                        (item, index) =>
                          index === 0 && <span>{item?.additionalSessionsApproved} </span>
                      )} */}
                    {!studentsData
                      ? 0
                      : studentsData?.studentAppointmentMetrics?.additionalSessionsApproved
                      ? studentsData?.studentAppointmentMetrics?.additionalSessionsApproved
                      : 0}
                  </strong>
                </p>
              </div>
            </div>
            <div className={classes.session}>
              <div>
                <p className={classes.blueback}>
                  Additional Sessions Remaining:
                  <strong className={classes.bluelabel}>
                    {/* {studentsData &&
                      studentsData?.studentAppointmentMetrics?.map(
                        (item, index) =>
                          index === 0 && <span>{item?.additionalSessionsRemaining} </span>
                      )} */}
                    {!studentsData
                      ? 0
                      : studentsData?.studentAppointmentMetrics?.additionalSessionsRemaining}
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.straightline} />
        </Grid>
        <h6 className="timeZoneTxt" style={{ marginLeft: '20px' }}>
          <span>All times shown in Mountain(MT) </span> Hawaiian Time = -3 hrs, Alaskan Time = -2
          hrs, Pacific Time = -1 hr, Central Time = +1 hr, Eastern Time = +2 hrs
        </h6>

        <Grid item xs={12}>
          <TableContainer className={classes.updatetable}>
            <Table className={classes.table} aria-label="simple table">
              {data && (
                <GridComponent
                  key={Math.floor(Math.random() * 100)}
                  allowTextWrap
                  width="auto"
                  allowPaging
                  pageSettings={{ pageSizes: [15, 25, 50, 100], pageSize: 15, pageCount: 5 }}
                  dataSource={data?.appointmentProfile || []}
                  filterSettings={filterOption}
                  // allowFiltering
                  allowSorting
                >
                  <ColumnsDirective>
                    <ColumnDirective width="20" />
                    <ColumnDirective
                      field="session_date"
                      headerText="Appointment Date"
                      width="100"
                    />
                    <ColumnDirective
                      field="session_time"
                      headerText="Appointment Time"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="provider_name"
                      headerText="Provider"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="service"
                      headerText="Service"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="date_requested"
                      template={(dates) => (
                        <>
                          <div>{moment(dates?.date_requested).format('MM-DD-YYYY')}</div>
                        </>
                      )}
                      headerText="Date Requested"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="status"
                      headerText="Status"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      field="attendance"
                      headerText="Attendance"
                      width="100"
                      allowFiltering={false}
                    />
                    <ColumnDirective
                      headerText="Feedback Client|Provider"
                      template={(data) => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginLeft: '10px'
                          }}
                        >
                          <div style={{ marginRight: '22px' }}>
                            {data?.client_rate === 'YES' ? (
                              data?.client_seeing_rate === 'YES' &&
                              data?.client_listening_rate === 'YES' ? (
                                <Tooltip
                                  title={client(
                                    data?.client_seeing_rate,
                                    data?.client_listening_rate,
                                    data?.client_comment
                                  )}
                                  arrow
                                >
                                  <ThumbUpAltIcon style={{ color: 'green' }} />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={client(
                                    data?.client_seeing_rate,
                                    data?.client_listening_rate,
                                    data?.client_comment
                                  )}
                                  style={{ cursor: 'pointer' }}
                                  arrow
                                >
                                  <ThumbDownAltIcon style={{ color: 'red' }} />
                                </Tooltip>
                              )
                            ) : (
                              <Question style={{ fontSize: '22px', margin: '5px' }} />
                            )}
                          </div>
                          <div style={{ marginLeft: '22px' }}>
                            {data?.provider_rate === 'YES' ? (
                              data?.provider_seeing_rate === 'YES' &&
                              data?.provider_listening_rate === 'YES' ? (
                                <Tooltip
                                  title={provider(
                                    data?.provider_seeing_rate,
                                    data?.provider_listening_rate,
                                    data?.provider_comment
                                  )}
                                  style={{ cursor: 'pointer' }}
                                  arrow
                                >
                                  <ThumbUpAltIcon style={{ marginRight: '22px', color: 'green' }} />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={provider(
                                    data?.provider_seeing_rate,
                                    data?.provider_listening_rate,
                                    data?.provider_comment
                                  )}
                                  style={{ cursor: 'pointer' }}
                                  arrow
                                >
                                  <ThumbDownAltIcon style={{ color: 'red' }} />
                                </Tooltip>
                              )
                            ) : (
                              <Question style={{ fontSize: '22px', margin: '5px' }} />
                            )}
                          </div>
                        </div>
                      )}
                      width="100"
                      allowFiltering={false}
                    />
                  </ColumnsDirective>
                  <Inject services={[Filter, Search, Sort, Page, DetailRow]} />
                </GridComponent>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {isLoading()}
    </div>
  )
}

export default AppointmentInfo
