import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid, Typography, Box, TextField, Link } from '@material-ui/core'
import moment from 'moment'
import { theme } from 'config/constants/theme'
import ProgressIndicator from '../../panel/progress-indicator/ProgressIndicator'
import { InfoButton } from '../../panel/button/InfoButton'
import Paper from '../../panel/paper/Paper'
import AppBar from '../../panel/app-bar/AppBar'
import Footer from '../../panel/footer/Footer'
import { useAuth } from '../../../../hooks/useAuth'
import {
  useAuthenticationTokensQuery,
  ExecutionQuery,
  QueryState
} from '../../../providers/auth/useAuthenticationTokensQuery'
import BackgroundImage from '../../../../assets/illustrations/backgroundimatterimage.png'

const backgroundStyle = {
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh'
}
const TOKENS_ITEM_KEY = 'authentication:tokens'
const VERIFIER_ITEM_KEY = 'authentication:verifier'

export const MfaValidation = () => {
  const initailValues = { otp: null }
  const [formValues, setFormValues] = useState(initailValues)
  const [formErrors, setFormErrors] = useState({ otp: '' })
  const [showSpinner, setSpinner] = useState<boolean>(false)
  const [otpErrorMessage, setOTPErrorMessage] = useState<string>('')
  const [sentBy, setSentBy] = useState<string>()
  const { isAuthenticated, setFallbackTokens } = useAuth()
  const location = useLocation()
  const { state }: any = location
  const [timer, setTimer] = useState(-1)
  const [
    executeQuery,
    { data: tokensQueryResult, loading: tokensQueryLoading, reset: resetTokensQuery }
  ]: [ExecutionQuery, QueryState] = useAuthenticationTokensQuery()
  const handleChanges = (e) => {
    setFormValues({ otp: e.target.value })
    setFormErrors({ otp: '' })
  }
  const validateFormErrors = (values) => {
    const errors = { otp: '' }

    if (!values.otp) errors.otp = 'Required'
    else {
      const otpPattern = /^[0-9]{6}$/

      if (!otpPattern.test(values.otp))
        errors.otp = 'Please provide a valid 6-digit verification code.'
    }

    return errors
  }
  const otpGenerateFun = async (otp) => {
    setSpinner(true)
    setOTPErrorMessage('')
    try {
      await executeQuery(
        localStorage.getItem('code') as string,
        localStorage.getItem(VERIFIER_ITEM_KEY),
        formValues?.otp
      )
      setSpinner(false)
    } catch (e) {
      const errmsg = 'Invalid or expired verification code.'
      setOTPErrorMessage(errmsg)
      setSpinner(false)
    }
  }
  const sendForOTP = () => {
    setOTPErrorMessage('')
    const validationErrors = validateFormErrors(formValues)
    let hasErrors = false
    Object.values(validationErrors).forEach((value) => {
      if (value) hasErrors = true
    })

    if (hasErrors) setFormErrors(validationErrors)
    else otpGenerateFun(formValues.otp)
  }
  const resendSendOTP = async () => {
    setSpinner(true)
    setOTPErrorMessage('')
    setFormValues({ otp: null })
    setFormErrors({ otp: '' })
    try {
      await executeQuery(
        localStorage.getItem('code') as string,
        localStorage.getItem(VERIFIER_ITEM_KEY),
        null
      )
      setSpinner(false)
    } catch (e) {
      const errmsg = 'Verification code sent failed.'
      setOTPErrorMessage(errmsg)
      setSpinner(false)
    }
  }
  useEffect(() => {
    if (tokensQueryResult?.id) {
      localStorage.setItem(TOKENS_ITEM_KEY, JSON.stringify(tokensQueryResult))
      localStorage.removeItem('lg_nm')
      setFallbackTokens(tokensQueryResult, '/')
    }
  }, [tokensQueryResult, setFallbackTokens])
  useEffect(() => {
    if (state?.isMfa) {
      setSentBy(state?.sentBy)
      const currenttime = moment().format()
      const otptime = moment(new Date(state?.epoch * 1000.0)).format()
      const durationsec = moment(otptime).diff(currenttime, 'seconds')
      const duration = durationsec
      setTimer(duration)
    }
  }, [state])
  useEffect(() => {
    if (tokensQueryResult?.isMfa) {
      const sentBy: any = tokensQueryResult?.sentBy
      setSentBy(sentBy)
      const epoch: any = tokensQueryResult?.epoch
      const currenttime = moment().format()
      const otptime = moment(new Date(epoch * 1000.0)).format()
      const durationsec = moment(otptime).diff(currenttime, 'seconds')
      const duration = durationsec
      setTimer(duration)
    }
  }, [tokensQueryResult])
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (state && state?.isMfa && timer !== 0) setTimer((prevTimer) => prevTimer - 1)
    }, 1000)

    if (timer === 0) clearInterval(timerInterval)

    return () => clearInterval(timerInterval)
  }, [timer, state])
  const handleEnterKey = (ev) => {
    if (ev.key === 'Enter') ev.preventDefault()
  }

  return (
    <>
      {state && localStorage.getItem('lg_nm') ? (
        <Grid container direction="column" justify="space-between" alignItems="center">
          <AppBar />
          {showSpinner ? <ProgressIndicator fullHeight /> : ''}
          <Grid container style={{ ...backgroundStyle, minHeight: 'calc(100vh - 70px - 85px)' }}>
            <Grid item sm={5} md={4} lg={3} style={{ margin: 'auto' }}>
              <Paper style={{ border: 'solid 1px #c7c7c7' }}>
                <Box px={3} py={4}>
                  <Typography variant="h1">Verification Code</Typography>
                </Box>
                <Box p={3} pt={0}>
                  <form>
                    <Grid container spacing={1}>
                      <Grid item sm={12} md={12} xs={12} lg={12}>
                        <Typography style={{ fontSize: '16px' }}>
                          We sent an email to <br />
                          <strong
                            style={{
                              fontSize: '17px',
                              fontWeight: 'bold',
                              wordBreak: 'break-all'
                            }}
                          >
                            {sentBy}.
                          </strong>
                          <br /> Please enter the verfication code to Login.
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={12} xs={12} lg={12}>
                        <TextField
                          label="Verification Code"
                          fullWidth
                          variant="filled"
                          name="otp"
                          onChange={handleChanges}
                          value={formValues?.otp ? formValues?.otp : ''}
                          onKeyPress={(ev) => handleEnterKey(ev)}
                        />
                      </Grid>
                      {formErrors.otp && (
                        <Grid item style={{ paddingTop: '0px' }}>
                          <div style={{ color: theme.palette.error.dark }}>{formErrors.otp}</div>
                        </Grid>
                      )}
                      {otpErrorMessage && (
                        <Grid item style={{ paddingTop: '0px' }}>
                          <div style={{ color: theme.palette.error.dark }}>{otpErrorMessage}</div>
                        </Grid>
                      )}
                      {timer !== 0 && (
                        <Grid item sm={12} md={12} xs={12} lg={12}>
                          <Typography>
                            Verification Code Expires In:{' '}
                            <span style={{ fontWeight: 500 }}>
                              {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}
                            </span>
                          </Typography>
                        </Grid>
                      )}
                      {timer === 0 && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <div style={{ textAlign: 'left' }}>
                            <Typography
                              variant="body2"
                              color="error"
                              style={{ marginRight: '10px' }}
                            >
                              Verification Code is expired
                            </Typography>
                          </div>
                          <div style={{ textAlign: 'right' }}>
                            <Link
                              style={{ color: theme.palette.info.dark, cursor: 'pointer' }}
                              onClick={resendSendOTP}
                            >
                              Resend Verification Code
                            </Link>
                          </div>
                        </div>
                      )}
                      <Grid item sm={12} md={12} xs={12} lg={12}>
                        <Box
                          display="flex"
                          p={0}
                          mt={1}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <InfoButton
                            variant="contained"
                            size="large"
                            type="button"
                            disabled={timer === 0}
                            onClick={() => sendForOTP()}
                          >
                            Login
                          </InfoButton>
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={12} xs={12} lg={12}>
                        <Typography align="center">
                          You can request a new code after 5 minutes.
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Footer />
        </Grid>
      ) : (
        <ProgressIndicator fullHeight />
      )}
    </>
  )
}
