/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useMemo, useEffect } from 'react'
import ProgressIndicator from 'components/ui/panel/progress-indicator/ProgressIndicator'
import {
  Role,
  // useListStudentsQuery,
  useCurrentUserQuery,
  useStudentSelectLazyQuery,
  useListStudentsLazyQuery,
  useUserQuery
} from 'graphql/hooks.generated'
import { useHistory } from 'react-router-dom'
import { Form, FormRenderProps } from 'react-final-form'
import { CheckboxData, DatePicker, TextField, Select, Checkboxes } from 'mui-rff'
import { Grid, MenuItem } from '@material-ui/core'
import { Decorator } from 'final-form'
import { PhoneInput } from 'components/ui/form/phone-input/PhoneInput'
import { StateSelect } from 'components/ui/form/state-select/StateSelect'
import { TimeZoneSelect } from 'components/ui/form/time-zone-select/TimeZoneSelect'
import { MaxAgeSelect } from 'components/ui/form/multiselect-dropdown/MaxAge'
import { Section } from '../../../../components/ui/panel/section/Section'
import { DistrictSelect } from '../../../../components/ui/form/district-select/DistrictSelect'
import { InfoButton } from '../../../../components/ui/panel/button/InfoButton'
import Button from '../../../../components/ui/panel/button/Button'
import { useCurrentUser } from '../../../../graphql/hooks/useCurrentUser/useCurrentUser'
import { createValidator } from './userValidator'
import { calculator } from './userCalculator'
import { UserFormModel } from './UserFormModel'
import { useStyles } from '../../../../components/ui/my-generated-reports/Styles'
import { TherapistDisciplineSelect } from '../../../../components/ui/form/therapist-discipline-select/TherapistDisciplineSelect'
import { AgesServedSelect } from '../../../../components/ui/form/multiselect-dropdown/AgesServedSelect'
import { CountiesSelect } from '../../../../components/ui/form/multiselect-dropdown/CountiesSelect'
import { EthnicGroupSelect } from '../../../../components/ui/form/multiselect-dropdown/EthnicGroupSelect'
import { InsuranceSelect } from '../../../../components/ui/form/multiselect-dropdown/InsuranceSelect'
import { SignalServicesSelect } from '../../../../components/ui/form/multiselect-dropdown/SignalServicesSelect'
import { SpecialitiesSelect } from '../../../../components/ui/form/multiselect-dropdown/SpecialitiesSelect'
import { TherapistSelect } from '../../../../components/ui/form/multiselect-dropdown/TherapistDesciplineSelect'
import { PrimaryLaguageSelect } from '../../../../components/ui/form/multiselect-dropdown/PrimaryLanguageSelect'
import { AgencySelect } from '../../../../components/ui/form/multiselect-dropdown/AgencySelect'
import { ExperienceSelect } from '../../../../components/ui/form/multiselect-dropdown/experienceSelect'
import { UserGenderSelect } from '../../../../components/ui/form/user-gender-select/UserGenderSelect'

function getAllowedRolesOptions(isTtAdmin: boolean) {
  const allowedRoles: CheckboxData[] = []
  allowedRoles.push(
    // { label: 'Parent', value: Role.Parent },
    { label: 'Administrator / Care Navigator', value: Role.SchoolAdmin },
    // { label: 'TeleTeachers Therapist', value: Role.Therapist },
    { label: 'Provider', value: Role.Therapist },
    { label: 'Agency Supervisor', value: Role.AgencySupervisor },
    { label: 'Agency Supervisor / Provider', value: Role.AgencySupervisorProvider }
  )

  if (isTtAdmin)
    allowedRoles
      .push
      // { label: 'School Admin', value: Role.SchoolAdmin },
      // { label: 'TeleTeachers Therapist', value: Role.Therapist }
      // { label: 'TeleTeachers Therapist', value: Role.Therapist }
      ()

  return allowedRoles
}

const checkboxData: CheckboxData[] = [{ label: 'This User is Care Navigator', value: true }]
const mfaCheckboxData: CheckboxData[] = [{ label: 'Enable MFA for user', value: true }]

export interface UserFormProps {
  onSubmit(values: UserFormModel): void
  submitLabel: string
  isUpdate?: boolean
  initialValues?: Partial<UserFormModel> | null
  userdata?: any
  decorators?: Decorator<UserFormModel, Partial<UserFormModel>>[]
}

export const UserForm = (props: UserFormProps) => {
  const [value, setValue] = useState('')
  const classes = useStyles()
  const [allstudentQuery, { data, loading }] = useListStudentsLazyQuery()
  const [studentQuery, { data: distStudents, loading: distStuLoading }] = useStudentSelectLazyQuery(
    {
      fetchPolicy: 'network-only'
    }
  )
  let studentdata
  let sendInvite = false
  const [changeDistId, setChangeDistId] = useState(-1)
  const { hasRole, user } = useCurrentUser()
  const currentUserdata = useCurrentUserQuery({ fetchPolicy: 'cache-only' })
  const history = useHistory()
  const { initialValues, onSubmit, isUpdate, userdata } = props
  const isTtAdmin = hasRole(Role.TeleteachersAdmin)
  const isDAdmin = hasRole(Role.SchoolAdmin)
  const disableEmail = initialValues?.roles
  const [changeMilValue, setChangeMailValue] = useState(false)
  const [mailv, setMailv] = React.useState(initialValues?.email)
  let enableValue

  if (disableEmail?.includes(Role.SchoolAdmin) || disableEmail?.includes(Role.TeleteachersAdmin))
    enableValue = true
  else enableValue = false

  const validator = useMemo(() => createValidator(user), [user])
  const fetchStudents = useMemo(
    () => ({ district_id }) =>
      studentQuery({
        variables: {
          district_id
        }
      }),
    [studentQuery]
  )
  const fetchAllStudents = useMemo(() => () => allstudentQuery(), [allstudentQuery])
  useEffect(() => {
    if (isDAdmin) {
      if (currentUserdata) {
        const inputdata = {
          district_id: Number(currentUserdata?.data?.currentUser?.district?.id)
        }
        fetchStudents(inputdata)
      }
    } else fetchAllStudents()
  }, [currentUserdata, fetchStudents, fetchAllStudents, isDAdmin])

  if (distStudents) studentdata = distStudents
  else studentdata = data

  useEffect(() => {
    const userMails = initialValues?.email

    if (userMails !== mailv && mailv !== undefined) setChangeMailValue(true)
    else setChangeMailValue(false)
  }, [setChangeMailValue, initialValues, mailv])
  const onChangeCaptureHandler = (e) => {
    if (e.target.value.length) setMailv(e.target.value)
  }
  const usersubmit = (values) => {
    onSubmit({ ...values, sendInvite, mailVlaue: changeMilValue })
  }
  const updateInvite = () => {
    sendInvite = false
  }
  const resendInvite = () => {
    sendInvite = true
  }
  const renderForm = (renderProps: FormRenderProps<UserFormModel>) => {
    const { handleSubmit, values } = renderProps
    // useEffect(() => {
    //   if (values.max_age <= values.min_age)
    // })
    const hasParentRoleSelected = values.roles?.includes(Role.Parent)
    const hasAdminSelected =
      values.roles?.includes(Role.SchoolAdmin) || values.roles?.includes(Role.TeleteachersAdmin)
    const hasTherapistRoleSelected =
      values.roles?.includes(Role.Therapist) || values.roles?.includes(Role.SchoolTherapist)
    const allowedRoles: CheckboxData[] = getAllowedRolesOptions(isTtAdmin)
    const hasAgencySupervisorProvider = values?.roles?.includes(Role.AgencySupervisorProvider)
    const hasAgencySupervisor = values?.roles?.includes(Role.AgencySupervisor)
    const therapistRoles = [Role.Therapist, Role.SchoolTherapist]
    // const requireAllTherapistroles = (roles: Role[] | undefined) =>
    //   therapistRoles.filter((role) => roles?.includes(role)).length > 0

    if (isTtAdmin) {
      const did = Number(values.district)

      if (values.district && did !== changeDistId) {
        const inputdata = {
          district_id: did
        }
        fetchStudents(inputdata)
        setChangeDistId(did)
      }
    }

    const handleChange = (event) => {
      setValue(event.target.value)
      renderProps.values.roles = [event.target.value]

      if (event.target.value === 'PARENT') setValue(Role.Parent)
      if (event.target.value === 'SCHOOL_ADMIN') setValue(Role.SchoolAdmin)
      if (event.target.value === 'THERAPIST') setValue(Role.Therapist)
      if (event.target.value === 'SCHOOL_THERAPIST') setValue(Role.SchoolTherapist)
      if (event.target.value === 'TELETEACHERS_ADMIN') setValue(Role.TeleteachersAdmin)
      if (event.target.value === 'AGENCY_SUPERVISOR_PROVIDER')
        setValue(Role.AgencySupervisorProvider)
      if (event.target.value === 'AGENCY_SUPERVISOR') setValue(Role.AgencySupervisor)
    }
    const rolePermissions: { [key: string]: string[] } = {
      [Role.Therapist]: [Role.AgencySupervisorProvider, Role.Therapist],
      [Role.AgencySupervisorProvider]: [Role.Therapist, Role.AgencySupervisorProvider],
      [Role.AgencySupervisor]: [
        Role.AgencySupervisor,
        Role.Therapist,
        Role.AgencySupervisorProvider
      ]
    }
    const getUpdatedCheckboxes = (currentRole: string): CheckboxData[] => {
      const enabledRoles = rolePermissions[currentRole] || []

      return allowedRoles.map((role: any) => ({
        ...role,
        disabled: !enabledRoles.includes(role.value)
      }))
    }
    console.log(initialValues?.signalDiscipline, initialValues?.timeZone, 'initialValues')
    const allowedRole = getUpdatedCheckboxes(userdata?.roles?.[0])

    return (
      <form>
        {(distStuLoading || loading) && <ProgressIndicator fullHeight />}

        <Section
          title={initialValues ? 'Edit user' : 'New user'}
          subtitle="Enter the required information for the user you want to send and invite to."
        >
          <Grid container spacing={2}>
            <Grid item sm={5}>
              <TextField
                required
                variant="filled"
                name="firstName"
                label="First Name"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={2}>
              <TextField
                variant="filled"
                name="middleInitial"
                label="Middle initial"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={5}>
              <TextField
                required
                variant="filled"
                name="lastName"
                label="Last Name"
                className={classes.labelField}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                required
                disabled={enableValue}
                variant="filled"
                name="email"
                label="Email address"
                className={classes.labelField}
                onChangeCapture={onChangeCaptureHandler}
              />
            </Grid>
            {!isDAdmin && (
              <Grid item sm={12}>
                <DistrictSelect required label="District" name="district" disabled={isUpdate} />
              </Grid>
            )}
            {isDAdmin && (
              <Grid item sm={12}>
                <Select
                  label="District"
                  name="district"
                  variant="filled"
                  required
                  className={classes.selectDropdownUserForm}
                >
                  {currentUserdata && (
                    <MenuItem
                      key={Number(currentUserdata?.data?.currentUser?.district?.id)}
                      value={currentUserdata?.data?.currentUser?.district?.id}
                    >
                      {currentUserdata?.data?.currentUser?.district?.name}
                    </MenuItem>
                  )}
                </Select>
              </Grid>
            )}
            <Grid item sm={5} />
            <Grid item sm={12}>
              <label style={{ display: 'grid' }} htmlFor="roles">
                <div style={{ color: 'lightslategray' }}>Roles *</div>
                <br />
                {/* <Checkboxes name="roles" label="Roles" required data={allowedRoles} />  */}
                {initialValues ? (
                  <>
                    {allowedRole.map((item, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <span key={i} style={{ marginBottom: '7px', fontSize: '15px' }}>
                        <input
                          type="radio"
                          name="roles"
                          disabled={item.disabled}
                          value={item.value as string}
                          onChange={handleChange}
                          checked={item.value === value || initialValues?.roles?.[0] === item.value}
                          required
                        />
                        {item.label}
                      </span>
                    ))}
                  </>
                ) : (
                  <>
                    {allowedRoles.map((item, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <span key={i} style={{ marginBottom: '7px', fontSize: '15px' }}>
                        <input
                          type="radio"
                          name="roles"
                          disabled={item.disabled}
                          value={item.value as string}
                          onChange={handleChange}
                          checked={item.value === value}
                          required
                        />
                        {item.label}
                      </span>
                    ))}
                  </>
                )}
              </label>
            </Grid>
            {hasAgencySupervisor ? (
              <>
                <Grid item sm={4}>
                  <PhoneInput
                    fullWidth
                    required
                    variant="filled"
                    name="mobilePhone"
                    label="Work Phone Number"
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    name="streetAddress"
                    label="Agency Address"
                    className={classes.labelField}
                  />
                </Grid>
                <Grid item sm={4}>
                  <AgencySelect label="Agency" name="signalAgencies" required />
                </Grid>
                <Grid item sm={8}>
                  <Checkboxes name="is_mfa" required={false} data={mfaCheckboxData} />
                </Grid>
              </>
            ) : null}

            {hasAdminSelected ? (
              <>
                <Grid item sm={4}>
                  <Checkboxes name="waitlist_assignee" required={false} data={checkboxData} />
                </Grid>
                <Grid item sm={4}>
                  <Checkboxes name="is_mfa" required={false} data={mfaCheckboxData} />
                </Grid>
              </>
            ) : null}

            {hasTherapistRoleSelected || hasAgencySupervisorProvider ? (
              <>
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    name="preferred_name"
                    label="Provider Preferred Name"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TherapistSelect required label="Provider discipline" name="signalDiscipline" />
                </Grid>

                <Grid item sm={4}>
                  <TimeZoneSelect name="timeZone" label="Time Zone" />
                </Grid>

                <Grid item sm={4}>
                  <UserGenderSelect required name="gender" label="Gender" />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    name="hourlyRate"
                    label="Hourly Rate"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    name="streetAddress"
                    label="Agency Address"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <PhoneInput
                    fullWidth
                    required
                    variant="filled"
                    name="mobilePhone"
                    label="Work Phone Number"
                  />
                </Grid>

                <Grid item sm={4}>
                  <PhoneInput fullWidth variant="filled" name="homePhone" label="Mobile Phone" />
                </Grid>

                <Grid item sm={4}>
                  <PhoneInput
                    fullWidth
                    required
                    variant="filled"
                    name="clinician_phone"
                    label="Clinician Phone"
                  />
                </Grid>

                <Grid item sm={4}>
                  <StateSelect name="stateLicense" label="State License" />
                  {/* {data?.states?.length ? (
        <Select name="stateLicense" label="State License" required variant="filled">
          {data?.states?.map((state) => (
            <MenuItem key={state.id} value={state.id}>
              {state.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        ''
      )} */}
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    name="licenseNumber"
                    label="License Number"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    name="npi"
                    label="NPI#"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="filled"
                    name="degree_held"
                    label="Degree Held"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="filled"
                    name="school_attended"
                    label="School Attended"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="filled"
                    name="specialized_training"
                    label="Specialized Training"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="filled"
                    name="supervisor_name_and_credentials"
                    label="Supervisor Name And Credentials"
                    className={classes.labelField}
                  />
                </Grid>
                {/* {hasTherapistRoleSelected ? (
              <Grid item sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="filled"
                    name="streetAddress"
                    label="Street Address"
                  />
                </Grid>
                  )} */}
                <Grid item sm={4}>
                  <DatePicker
                    clearable
                    maxDate={new Date()}
                    name="certDate"
                    label="Cert Date"
                    inputVariant="filled"
                    format="MM/dd/yyyy"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <DatePicker
                    clearable
                    name="certExpireDate"
                    label="Cert Expire Date"
                    inputVariant="filled"
                    format="MM/dd/yyyy"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    name="zoomId"
                    label="Zoom ID"
                    className={classes.labelField}
                  />
                </Grid>

                <Grid item sm={4}>
                  <AgesServedSelect label="Min Age" name="min_age" required />
                </Grid>
                <Grid item sm={4}>
                  <MaxAgeSelect label="Max Age" name="max_age" required />
                </Grid>
                <Grid item sm={4}>
                  <EthnicGroupSelect label="Ethnic Group" name="signalEthnicGroup" />
                </Grid>
                <Grid item sm={4}>
                  <CountiesSelect label="Counties Served*" name="signalCounties" />
                </Grid>
                <Grid item sm={4}>
                  <InsuranceSelect label="Insurance Accepted" name="signalInsurance" />
                </Grid>
                <Grid item sm={4}>
                  <SignalServicesSelect label="Services" name="signalServiceId" />
                </Grid>
                <Grid item sm={4}>
                  <SpecialitiesSelect label="Specialities" name="signalSpecilities" />
                </Grid>
                <Grid item sm={4}>
                  <PrimaryLaguageSelect label="Offered Languages" name="signalLanguage" />
                </Grid>
                <Grid item sm={4}>
                  <AgencySelect label="Agency" name="signalAgencies" />
                </Grid>
                <Grid item sm={4}>
                  <ExperienceSelect label="Experience" name="signalUserExperience" />
                </Grid>

                <Grid item sm={8}>
                  <Checkboxes name="is_mfa" required={false} data={mfaCheckboxData} />
                </Grid>
              </>
            ) : null}

            {hasParentRoleSelected ? (
              <Grid item sm={12}>
                {/* <StudentSelect required multiple label="Students" name="students" /> */}
                {studentdata?.students?.length ? (
                  <Select
                    name="studentId"
                    label="Clients"
                    required
                    variant="filled"
                    className={classes.selectDropdownUserForm}
                  >
                    {studentdata?.students?.map((student) => (
                      <MenuItem key={student.id} value={student.id}>
                        {student.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Select
                    name="studentId"
                    label="Clients"
                    required
                    variant="filled"
                    className={classes.selectDropdown}
                  >
                    {data?.students?.map((student) => (
                      <MenuItem key={student.id} value={student.id}>
                        NO CLIENTS FOUND
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Grid>
            ) : null}
            <Grid item sm={12} style={{ marginTop: 24 }}>
              <InfoButton
                variant="contained"
                size="large"
                // onClick={handleSubmit}
                onClick={() => {
                  updateInvite()
                  handleSubmit()
                }}
                style={{ marginRight: 16 }}
              >
                {props.submitLabel}
              </InfoButton>
              <Button variant="outlined" color="primary" size="large" onClick={history.goBack}>
                Cancel
              </Button>
              {initialValues?.status === 'INACTIVE' && initialValues.password === null && (
                <InfoButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    resendInvite()
                    handleSubmit()
                  }}
                  style={{ marginLeft: 16 }}
                >
                  Send Invite
                </InfoButton>
              )}
            </Grid>
          </Grid>
        </Section>
      </form>
    )
  }

  return (
    <Form<UserFormModel>
      initialValues={initialValues ?? {}}
      validate={validator}
      render={renderForm}
      // onSubmit={onSubmit}
      onSubmit={usersubmit}
      // decorators={[calculator]}
    />
  )
}
